import { FollowUpSteps, ScrollLinkWrapper } from '@ads-core/components';
import { FollowUpStepCardType, FollowUpStepProps, FollowUpStepsProps } from '@ads-core/types';
import { OutageFollowUpStepsProps } from 'components/OutageHero';
import React from 'react';

type OutageHeroFollowUpStepsProps = {
  followUpStepsSectionElectricity?: OutageFollowUpStepsProps;
  followUpStepsSectionGas?: OutageFollowUpStepsProps;
  energyType?: string;
};

export const OutageHeroFollowupSteps = (props: OutageHeroFollowUpStepsProps) => {
  const followUpSteps = getFollowUpStepsByEnergyType(
    props.energyType,
    props.followUpStepsSectionElectricity,
    props.followUpStepsSectionGas
  );

  if (!followUpSteps) return null;

  return (
    <ScrollLinkWrapper anchorId="stappenplan">
      <FollowUpSteps {...followUpSteps} />
    </ScrollLinkWrapper>
  );
};

const getFollowUpStepsByEnergyType = (
  energyType: string | null | undefined,
  electricTopTasks: OutageFollowUpStepsProps | undefined,
  gasTopTasks: OutageFollowUpStepsProps | undefined
): FollowUpStepsProps | undefined => {
  switch (energyType) {
    case 'Stroom':
      return {
        tag: electricTopTasks?.fields?.tag?.value,
        title: electricTopTasks?.fields?.title?.value,
        followUpSteps: FollowUpStepsSection(electricTopTasks),
      };

    case 'Gas':
      return {
        tag: gasTopTasks?.fields?.tag?.value,
        title: gasTopTasks?.fields?.title?.value,
        followUpSteps: FollowUpStepsSection(gasTopTasks),
      };
    default:
      return undefined;
  }
};

const FollowUpStepsSection = (props: OutageFollowUpStepsProps | undefined): FollowUpStepProps[] => {
  if (!props) {
    return [];
  }

  return props.fields.followUpSteps.map((step) => {
    return {
      moreDetails: true,
      moreDetailsText: step.fields.moreDetailsText.value,
      moreDetailsTitle: step.fields.moreDetailsTitle.value,
      moreDetailsTag: step.fields.moreDetailsTag.value,
      moreDetailsButton: step.fields.moreDetailsButton.value,
      text: step.fields.text.value,
      title: step.fields.title.value,
      tag: step.fields.tag.value,
      type: step.fields.type.value as FollowUpStepCardType,
    };
  });
};
