import {
  ImageFieldValue,
  LinkField,
  RichTextField,
  TextField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SubpageHeroSideImageSitecore } from '@ads-core/components';
import { renderJssLink, SitecoreImage, SitecoreText } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';

type LinkListProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    anchor: {
      value: string;
    };
    title: {
      value: string;
    };
  };
};

export type SideImageHeroProps = ComponentProps & {
  fields: {
    linklist: LinkListProps[];
    textLink: LinkField;
    title: TextField;
    text: RichTextField;
    image: ImageFieldValue & { height: string; width: string; alt: string };
  };
};

const SideImageHero = (props: SideImageHeroProps) => {
  if (props.fields) {
    const { title, image, text, linklist, textLink } = props.fields;
    const explanationString = text.editable || text.value;

    const isEditor = !!title.editable;

    // We don't want Next/Link dependencies in our ads-core package, so we do basic mapping to relevant components on here on app level
    const linkList = linklist.map((item) => ({
      link: (
        <NextLink href={`#${item.fields.anchor.value}`}>
          <SitecoreText key={item.fields.anchor.value} field={item.fields.title} editable />
        </NextLink>
      ),
      arrowLink: <NextLink href={`#${item.fields.anchor.value}`} />,
    }));

    return (
      <SubpageHeroSideImageSitecore
        text={isEditor ? <SitecoreText field={title} editable /> : title.value?.toString()}
        explanation={explanationString}
        textLink={renderJssLink(textLink)}
        sideImage={<SitecoreImage field={image} editable />}
        linklist={linkList}
      />
    );
  }

  return null;
};

export default withDatasourceCheck()<SideImageHeroProps>(SideImageHero);
