import React from 'react';
import { useRouter } from 'next/router';

import { baseQueryParams } from 'src/utils';
import { useParams } from 'src/hooks/useParams';

import {
  IntegrationApi,
  IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps,
} from '@alliander-fe/api';

import {
  ListHeroView,
  Stack,
  Box,
  StackItem,
  ScrollLinkWrapper,
  Pagination,
} from '@ads-core/components';

import { SitecoreImage } from '@alliander-fe/jss-utils';
import { postalCodePattern, setReplaceText } from '@alliander-fe/validation';

import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';
import { useSitecoreContext } from 'src/context/SitecoreContext';
import { capitalizeLocationName } from 'src/utils/format';
import { rewriteEnergyType } from 'src/utils';

import { useQuery } from '@tanstack/react-query';
import OutageBlock from '../OutageBlock';
import { ExplanationLinks } from './_ExplanationLinks';

export const queryParams = baseQueryParams;

type LocationOutagesProps = {
  handleOutageDialog: (value: OutageProps) => void;
} & FilteredOutagesOnLocationProps;

export const LocationOutages = ({ handleOutageDialog, ...props }: LocationOutagesProps) => {
  const { isEditor } = useSitecoreContext();
  const router = useRouter();

  // get the url query params
  const { city, page } = useParams();

  // The limit per page when we show all resolved outages
  const allResolvedLimit = 10;

  /**
   * Flag to only show the [x] amount of most recent results.
   * When both resolvedOutages and allResolvedOutages are true (because contentmanagers made a mistake), resolvedOutages wins
   */
  const showRecentResolved = Boolean(props.onlyShowRecentlyResolvedOutages);
  const recentlyResolvedLimit = 2;

  const limit = showRecentResolved ? recentlyResolvedLimit : allResolvedLimit;
  const pageNumber = Math.max((Number(page) || 0) - 1, 0);

  const path = router.query.path || [];
  const urlLocation = path[path.length - 1];
  const locationIsPostalCode = path ? postalCodePattern.value.test(urlLocation) : false;

  const locationParams = city || path[path.length - 1] || null;
  const queryLocation = isEditor ? locationParams ?? '' : locationParams;

  // If showRecentResolved and showAllResolved are both false => fetch unresolved outages
  const fetchResolved = showRecentResolved;

  /**
   * Query to get the total count of outages on a location for pagination page count
   * This is a separate call because the Liander API is stupid
   */
  const totalCountQuery = useQuery({
    enabled: !!queryLocation && !showRecentResolved,
    queryKey: [queryLocation, showRecentResolved],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location: queryLocation,
        Resolved: fetchResolved,
        Amount: 0,
        ReturnCountOnly: true,
      }),
  });

  const { status, data } = useQuery({
    enabled: !!queryLocation,
    queryKey: [queryLocation, showRecentResolved, limit, pageNumber],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location: queryLocation,
        Resolved: fetchResolved,
        Amount: limit,
        Offset: pageNumber * limit,
        ReturnCountOnly: false,
      }),
    select: (data) => {
      return {
        ...data,
        outages: data?.outages?.map((outage) => {
          return {
            ...outage,
            energyType: rewriteEnergyType(outage.energyType),
          };
        }),
      };
    },
  });

  const noResolvedResults = fetchResolved && (data?.count === 0 || !data?.outages);

  if (!isEditor && (!queryLocation || !data || noResolvedResults)) return null;

  const resultCount = data?.count ?? 0;
  const totalCount = totalCountQuery.data?.count ?? resultCount;
  const location = locationIsPostalCode
    ? queryLocation?.toUpperCase() ?? ''
    : capitalizeLocationName(queryLocation ?? '');
  const outages = data?.outages;
  const amountOfPages = Math.floor(totalCount / limit);
  const currentPage = pageNumber <= amountOfPages ? pageNumber : amountOfPages;

  return (
    <>
      <ScrollLinkWrapper
        anchorId={showRecentResolved ? 'opgelosteonderbrekingen' : 'actueleonderbrekingen'}
      >
        <ListHeroView
          image={props.image?.src ? <SitecoreImage field={props.image} editable /> : undefined}
          variant={showRecentResolved ? 'contained' : 'breakout'}
          text={
            status === 'success'
              ? outagesTitle(
                  totalCount,
                  showRecentResolved ? props.title?.toString() : undefined,
                  location
                )
              : undefined
          }
          list={
            status === 'success' ? (
              <Box width="100%" overflowY="hidden" asChild>
                <Stack
                  direction={showRecentResolved ? { initial: 'row', md: 'column' } : 'column'}
                  gap={4}
                  isFullWidth
                >
                  {outages?.map((outage, index) => (
                    <Box
                      width={
                        showRecentResolved ? { initial: '90%', sm: '50%', md: '100%' } : '100%'
                      }
                      key={index}
                      asChild
                    >
                      <StackItem shrink={false}>
                        <OutageBlock.Container>
                          {showRecentResolved ? (
                            <OutageBlock.Resolved
                              {...outage}
                              handleToggleDialog={handleOutageDialog}
                            />
                          ) : null}

                          {!showRecentResolved ? (
                            <OutageBlock.Current
                              {...outage}
                              resolvedOutages={showRecentResolved}
                              handleToggleDialog={handleOutageDialog}
                            />
                          ) : null}
                        </OutageBlock.Container>
                      </StackItem>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ) : null
          }
          explanation={
            status === 'success' ? (
              <Stack gap={2} alignY="end">
                <ExplanationLinks
                  showRecentResolved={showRecentResolved}
                  hasOutages={Boolean(Number(totalCount))}
                  outageData={props}
                />
              </Stack>
            ) : undefined
          }
        />
      </ScrollLinkWrapper>
      {outages ? (
        <Pagination
          forcePage={currentPage}
          pageCount={totalCount ? totalCount / limit : 0}
          onPageChange={(e) => {
            const selectedPage = e.selected + 1;
            router.push({
              pathname: router.pathname,
              query: {
                ...router.query,
                [queryParams.page]: selectedPage,
              },
            });
          }}
        />
      ) : null}
    </>
  );
};

// Create the replace text for the outages based on the maintenance and outages.
const outagesTitle = (
  outages: number | undefined | null,
  resolvedTitle: string | undefined,
  places: string | undefined
): string => {
  if (resolvedTitle)
    return setReplaceText(resolvedTitle, { '{places}': places ? places : '' }) ?? '';

  switch (outages) {
    case 1:
      return `Er is ${outages} gas- of stroomonderbreking in ${places}`;
    case 0:
      return `Er is geen gas- of stroomonderbreking in ${places}`;
    default:
      return `Er zijn ${outages} gas- of stroomonderbrekingen in ${places}`;
  }
};
