import {
  getEnabledValidation,
  validationModels,
  validationModelNames,
  ValidationModels,
} from '@alliander-fe/validation';

const validationNames = Object.values(validationModelNames);

type ValidationNames = (typeof validationNames)[number];

type ValidationsModels = keyof typeof validationModels;

type ValidationMethodes = {
  [K in ValidationNames]: ValidationModels[0] | undefined;
};

type ValidationModelArray = [ValidationsModels, string][];

export const getValidationModels = (model: ValidationModels) => {
  const validations = {} as ValidationMethodes;
  const modelEntries = Object.entries(validationModels) as ValidationModelArray;

  modelEntries.map(([key, value]) => {
    const modelKey = validationModelNames[key];
    const enabledValidation = getEnabledValidation(value, model);

    if (modelKey) {
      validations[modelKey] = enabledValidation;
    }
  });

  return validations;
};
