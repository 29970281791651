import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { CheckmarkIcon, CloseIcon, InfoIcon, WarningIcon } from '../../icons/';
import { AsChild, InternalComponentProps } from '../Slot';
import { container as hidden } from '../VisuallyHidden/VisuallyHidden.css';
import { alert, AlertVariants } from './Alert.css';

type AlertType =
  | { role: 'alert'; id: string }
  | { role?: 'region'; id?: string };

type AlertProps = {
  children?: React.ReactNode;
} & AlertVariants &
  AlertType;

type AlertComponentProps = AlertProps & AsChild & InternalComponentProps;

export const isAlert = (
  variant?: string
): variant is AlertVariants['variant'] => {
  return variant
    ? ['error', 'info', 'warning', 'success'].includes(variant)
    : false;
};

export const Alert = React.forwardRef<HTMLDivElement, AlertComponentProps>(
  (
    {
      children,
      id,
      className = '',
      style = {},
      as = 'div',
      asChild,
      variant,
      role,
    },
    ref
  ) => {
    const Component = asChild ? Slot : as;

    const alertIcon = () => {
      switch (variant) {
        case 'info':
          return <InfoIcon />;
        case 'success':
          return <CheckmarkIcon />;
        case 'warning':
          return <WarningIcon />;
        default:
          return <CloseIcon />;
      }
    };

    const labelId = React.useId();
    const errorId = `${labelId}-${variant}`;

    return (
      <Component
        id={id}
        className={children ? clsx(alert({ variant }), className) : hidden}
        style={{ ...style }}
        ref={ref}
        role={role}
        aria-live="polite"
      >
        {children ? (
          <>
            {alertIcon()}
            <span id={errorId}>{children}</span>
          </>
        ) : null}
      </Component>
    );
  }
);
