import React from 'react';
import clsx from 'clsx';
import { Box, BoxProps } from '../Box';
import { Heading, HeadingProps } from '../Heading';
import { Stack } from '../Stack';
import { Button as ButtonComponent, ButtonProps } from '../Button';
import {
  ButtonLink as ButtonLinkComponent,
  ButtonLinkProps,
} from '../ButtonLink';
import { Text as TextComponent } from '../Text';

import * as styles from './Card.css';

type CardProps = {
  className?: string;
} & React.PropsWithChildren;

export const Card = ({ children }: CardProps) => {
  return (
    <Box position="relative" asChild>
      <Stack isFullHeight>{children}</Stack>
    </Box>
  );
};

type CardContainerProps = CardProps &
  Pick<BoxProps, 'bg' | 'height'> &
  styles.CardVariants;

export const Container = ({
  children,
  className,
  indentation,
  bg = 'containerLight',
  ...rest
}: CardContainerProps) => {
  const recipeClassnames = styles.card({ indentation });

  return (
    <Stack
      className={clsx(recipeClassnames, className)}
      gap={{ initial: 6, md: 8 }}
      isFullHeight
      asChild
    >
      <Box
        {...rest}
        bg={bg}
        borderRadius="brandXl"
        padding={{ initial: 8, md: 12 }}
        zIndex="1"
      >
        {children}
      </Box>
    </Stack>
  );
};

type CardTitleProps = {
  as?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  tag?: string;
} & CardProps &
  HeadingProps &
  Pick<BoxProps, 'height' | 'minHeight'>;

const Title = ({
  children,
  className,
  tag,
  height,
  minHeight,
  ...rest
}: CardTitleProps) => {
  return (
    <Stack className={className} gap={{ initial: 6, md: 8 }}>
      {tag ? (
        <TextComponent size="label" asChild>
          {tag}
        </TextComponent>
      ) : null}
      <Box height={height} minHeight={minHeight}>
        <Heading {...rest}>{children}</Heading>
      </Box>
    </Stack>
  );
};

const Text = ({ children, className }: CardProps) => {
  return (
    <TextComponent className={className} size="paragraph" asChild>
      {children}
    </TextComponent>
  );
};

type CardButtonProps = CardProps & ButtonProps;

const Button = ({ children, className, ...rest }: CardButtonProps) => {
  return (
    <ButtonComponent {...rest} className={clsx(className, styles.button)}>
      {children}
    </ButtonComponent>
  );
};

type CardButtonLinkProps = CardProps & ButtonLinkProps;

const ButtonLink = ({ children, className, ...rest }: CardButtonLinkProps) => {
  return (
    <ButtonLinkComponent {...rest} className={clsx(className, styles.button)}>
      {children}
    </ButtonLinkComponent>
  );
};

type CardImageProps = CardProps &
  Omit<BoxProps, 'width' | 'height' | 'as' | 'asChild'>;

const Image = ({ children, className, ...rest }: CardImageProps) => {
  const borderRadius = rest.borderRadius || 'brandXl';
  return (
    <Box
      {...rest}
      width="100%"
      height="100%"
      className={clsx(className, styles.image)}
      borderRadius={borderRadius}
      asChild
    >
      {children}
    </Box>
  );
};

Card.Title = Title;
Card.Text = Text;
Card.Container = Container;
Card.Image = Image;
Card.Button = Button;
Card.ButtonLink = ButtonLink;
