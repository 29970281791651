import { FaqSitecore, Heading, RichText, ScrollLinkWrapper } from '@ads-core/components';
import {
  TextField,
  RichTextField,
  LinkField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'src/jss/lib/component-props';

import React from 'react';
import { SitecoreRichText, SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';
import { useTrackingContext } from '@liander/context';

type FaqPropsJssProps = ComponentProps & {
  fields: {
    button: LinkField;
    tag: TextField;
    title: TextField;
    items: {
      displayName: string;
      id: string;
      name: string;
      url: string;
      fields: {
        answer: RichTextField;
        question: TextField;
      };
    }[];
  };
  params?: {
    anchor?: string;
  };
};

const Faq = (props: FaqPropsJssProps) => {
  const { trackAccordionChange } = useTrackingContext();

  const items = props.fields.items.map((item) => {
    const isEditor = item.fields.answer.editable || item.fields.question.editable;

    return {
      id: item.id,
      title: (
        <Heading size="h5" as="h3">
          <SitecoreText field={item.fields.question} editable />
        </Heading>
      ),
      titleName: item.fields.question.value,
      content: isEditor ? (
        <SitecoreRichText field={item.fields.answer} editable />
      ) : (
        <RichText>{item.fields.answer.value ?? undefined}</RichText>
      ),
    };
  });

  const handleAccordionOpen = (id: string, opened: boolean) => {
    const openedItem = props.fields.items.find((item) => item.id === id);
    if (!openedItem) return;

    const questionName = openedItem.fields.question.value;
    trackAccordionChange({ opened, actionDetail: (questionName || 'not available').toString() });
  };

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <FaqSitecore
        items={items}
        title={
          props.fields.title.value ? (
            <SitecoreText tag="h2" field={props.fields.title} editable />
          ) : undefined
        }
        tag={
          props.fields.tag.value ? <SitecoreText field={props.fields.tag} editable /> : undefined
        }
        button={renderJssLink(props.fields.button)}
        onAccordionOpen={handleAccordionOpen}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(Faq);
