import React from 'react';
import { LayoutProps } from 'src/pages/_app';

type LayoutSiteCoreContext = LayoutProps['layoutData']['sitecore']['context'];
type LayoutSiteCoreRoute = LayoutProps['layoutData']['sitecore']['route'];

type SitecoreContextProps = {
  context?: LayoutSiteCoreContext & {
    segment?: 'Grootzakelijk' | 'Over ons' | 'Thuis en kleinzakelijk';
  };
  route: LayoutSiteCoreRoute;
  isEditor: boolean | undefined;
};

const SitecoreContext = React.createContext<SitecoreContextProps | null>(null);

type SitecoreContextProviderProps = {
  children: React.ReactNode;
} & Omit<SitecoreContextProps, 'isEditor'>;

export const SitecoreContextProvider = ({
  children,
  context,
  route,
}: SitecoreContextProviderProps) => {
  return (
    <SitecoreContext.Provider value={{ context, isEditor: context?.pageEditing, route }}>
      {children}
    </SitecoreContext.Provider>
  );
};

export const useSitecoreContext = () => {
  const ctx = React.useContext(SitecoreContext);
  if (!ctx) {
    throw new Error('useSitecoreContext must be used within a SitecoreContextProvider');
  }

  return ctx;
};
