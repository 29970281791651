import { setReplaceText } from '@alliander-fe/validation';
import { FilteredNetworkCheckProps } from '..';
import { DialogStatusProps } from '../_DialogNetwork';

// Maps the api response to the correct response for the view
export const mappedNetworkAvailability = ({
  networkCheck,
  isOperationalGas,
  isOperationalElectricity,
  replaceText,
}: {
  networkCheck?: FilteredNetworkCheckProps;
  isOperationalGas?: boolean;
  isOperationalElectricity?: boolean;
  replaceText?: { [key: string]: string };
}): DialogStatusProps => {
  const replaceTextParameters = replaceText ? replaceText : { '{}': '' };

  if (!isOperationalGas && !isOperationalElectricity) {
    return {
      title: setReplaceText(networkCheck?.isNotGridOperatorTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.isNotGridOperatorText, replaceTextParameters),
      available: false,
    };
  }

  if (isOperationalGas && !isOperationalElectricity) {
    return {
      title:
        setReplaceText(networkCheck?.isGasNotElectricityOperatorTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.isGasNotElectricityOperatorText, replaceTextParameters),
      available: true,
    };
  }

  if (!isOperationalGas && isOperationalElectricity) {
    return {
      title:
        setReplaceText(networkCheck?.isElectricityNotGasOperatorTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.isElectricityNotGasOperatorText, replaceTextParameters),
      available: true,
    };
  }

  return {
    title:
      setReplaceText(networkCheck?.isElectricityAndGasOperatorTitle, replaceTextParameters) ?? '',
    message: setReplaceText(networkCheck?.isElectricityAndGasOperatorText, replaceTextParameters),
    available: true,
  };
};
