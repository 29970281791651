/**
 * @param cause could be 'Spontane storing', 'Weersinvloed', 'Bodemverzakking', 'Onderhoud' or 'Graafwerkzaamheden'.
 * @returns a boolean, in case of an outage we return true otherwise it we return false.
 */
export const isOutageCause = (cause: string | null | undefined) => {
  switch (cause) {
    case 'Spontane storing':
    case 'Weersinvloed':
    case 'Bodemverzakking':
    case 'Graafwerkzaamheden':
      return true;
    default:
      return false;
  }
};
