import { getValidationModel } from '../utils/helpers';
import { ValidationModels } from '../types/global';

/**
 * Checks if the input matches the validationModel and returns a message if theres a mismatch.
 *
 * @param validationModel
 * @param inputValue
 * @returns string | undefined
 */
export function isValidValidationModel(
  validationModel: ValidationModels[0] | undefined,
  inputValue: string
): string | undefined {
  if (validationModel && inputValue) {
    const validation = getValidationModel(validationModel);

    if (validation && !validation.value.test(inputValue)) {
      return validation.message;
    }
  }
}
