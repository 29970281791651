import { getDate, getMonth, getYear, isToday } from 'date-fns';
import { OutageData } from 'src/components/OutageModal';
import { setOutageEndDate } from './outageEndDate';

type GetEndDateAndTimeProps = {
  resolvedOutages?: boolean;
  showFullDate?: boolean;
} & Omit<OutageData, 'topTasks' | 'followUpSteps'>;

/**
 * @param estimatedEndTime "vandaag, 14:00u" or "Nog niet bekend" when the endDate is unknown
 * @param endDate "06 oktober" or "Onbekend" when the endDate is unknown
 * @param endTime "12:32u", "null" or "undefined"
 * @returns on resolvedOutages {label: 'Eindtijd:', description: `${displayDate(endDate)} om ${endTime}` : displayDate(endDate)}
 * @returns on unResolvedOutage {label: 'Verwachte eindtijd:', description: estimatedEndTime}
 */
export const outageEndDateAndTime = ({
  estimatedEndTime,
  resolvedOutages,
  showFullDate = true,
  endDate,
  endTime,
}: GetEndDateAndTimeProps): { label: string; description: string } | undefined => {
  if (!resolvedOutages && estimatedEndTime) {
    return {
      label: 'Verwachte eindtijd:',
      description: estimatedEndTime,
    };
  }

  if (resolvedOutages && endDate && endDate !== 'Nog niet bekend') {
    const fullDate = showFullDate ? outageDisplayDate(endDate) : endDate;

    return {
      label: 'Eindtijd:',
      description: endTime && endDate ? `${fullDate} om ${endTime}` : fullDate,
    };
  }

  return;
};

/**
 * Check if the date is today and set the string 'vandaag' if that's the case.
 * @param date '11 oktober', null or undefined
 * @returns string '', 'vandaag' or mapped date '11 oktober 2024'.
 */
const outageDisplayDate = (date: string | undefined | null): string => {
  if (!date) return '';

  const currentDate = new Date();
  const inputDate = new Date(date);

  const currentYear = getYear(currentDate);
  const inputDay = getDate(inputDate);
  const inputMonth = getMonth(inputDate);

  if (isToday(new Date(currentYear, inputMonth, inputDay))) {
    return 'vandaag';
  }

  return setOutageEndDate(date);
};
