import { isWithinInterval, subDays } from 'date-fns';

export const minMaxDateValidator = (date: string) => {
  const inputDate = new Date(date).getTime();
  const today = new Date();

  const maxDate = today.getTime();
  const minDate = subDays(today, 366).getTime();

  const inRange = isWithinInterval(inputDate, {
    start: minDate,
    end: maxDate,
  });

  return inRange;
};
