import { ButtonProps } from '@alliander-fe/sitecore-types';

import { CongestionLevel } from 'src/types';
import { FilteredCongestionCheckerParticulierProps } from 'components/CongestionCheckerParticulier';
import { setReplaceText } from '@alliander-fe/validation';

type MappedCongestionProps = {
  level: CongestionLevel | 'NotFound';
  disclaimer?: boolean;
  replaceText?: { [key: string]: string };
} & FilteredCongestionCheckerParticulierProps;

export type MappedCongestionDataProps = {
  feedbackTitle?: string;
  feedbackText?: string;
  feedbackPrimaryButton?: ButtonProps;
  feedbackSecondaryButton?: ButtonProps;

  disclaimer?: string;

  additionalInfoBlockTitle?: string;
  additionalInfoBlockText?: string;
  additionalInfoBlockPrimaryButton?: ButtonProps;

  additionalInfoBlockSecondaryButton?: ButtonProps;
  conclusionUpperText?: string;
  conclusionFirstPrimaryButton?: ButtonProps;
  conclusionFirstSecondaryButton?: ButtonProps;
  conclusionLowerText?: string;
  conclusionSecondPrimaryButton?: ButtonProps;
  conclusionSecondSecondaryButton?: ButtonProps;
};

export const mappedResponse = ({
  level,
  disclaimer,
  replaceText,
  ...congestionData
}: MappedCongestionProps): MappedCongestionDataProps => {
  const replaceTextParameters = replaceText ? replaceText : { '{}': '' };
  const disclaimerText = disclaimer
    ? congestionData.disclaimerTextCongestionNearbyConnection
    : undefined;

  switch (level) {
    case 'Purple':
      return {
        feedbackTitle: setReplaceText(congestionData.feedbackPurpleTitle, replaceTextParameters),
        feedbackText: setReplaceText(congestionData.feedbackPurpleText, replaceTextParameters),
        feedbackPrimaryButton: congestionData.feedbackPurplePrimaryButton,
        feedbackSecondaryButton: congestionData.feedbackPurpleSecondaryButton,

        disclaimer: disclaimerText,

        additionalInfoBlockTitle: setReplaceText(
          congestionData.additionalInfoBlockPurpleTitle,
          replaceTextParameters
        ),
        additionalInfoBlockText: setReplaceText(
          congestionData.additionalInfoBlockPurpleText,
          replaceTextParameters
        ),
        additionalInfoBlockPrimaryButton: congestionData.additionalInfoBlockPurplePrimaryButton,
        additionalInfoBlockSecondaryButton: congestionData.additionalInfoBlockPurpleSecondaryButton,

        conclusionUpperText: setReplaceText(
          congestionData.conclusionPurpleUpperText,
          replaceTextParameters
        ),
        conclusionFirstPrimaryButton: congestionData.conclusionPurpleFirstPrimaryButton,
        conclusionFirstSecondaryButton: congestionData.conclusionPurpleFirstSecondaryButton,
        conclusionLowerText: setReplaceText(
          congestionData.conclusionPurpleLowerText,
          replaceTextParameters
        ),

        conclusionSecondPrimaryButton: congestionData.conclusionPurpleSecondPrimaryButton,
        conclusionSecondSecondaryButton: congestionData.conclusionPurpleSecondSecondaryButton,
      };
    case 'Red':
      return {
        feedbackTitle: setReplaceText(congestionData.feedbackRedTitle, replaceTextParameters),
        feedbackText: setReplaceText(congestionData.feedbackRedText, replaceTextParameters),
        feedbackPrimaryButton: congestionData.feedbackRedPrimaryButton,
        feedbackSecondaryButton: congestionData.feedbackRedSecondaryButton,

        disclaimer: disclaimerText,

        additionalInfoBlockTitle: setReplaceText(
          congestionData.additionalInfoBlockRedTitle,
          replaceTextParameters
        ),
        additionalInfoBlockText: setReplaceText(
          congestionData.additionalInfoBlockRedText,
          replaceTextParameters
        ),
        additionalInfoBlockPrimaryButton: congestionData.additionalInfoBlockRedPrimaryButton,
        additionalInfoBlockSecondaryButton: congestionData.additionalInfoBlockRedSecondaryButton,

        conclusionUpperText: setReplaceText(
          congestionData.conclusionRedUpperText,
          replaceTextParameters
        ),
        conclusionFirstPrimaryButton: congestionData.conclusionRedFirstPrimaryButton,
        conclusionFirstSecondaryButton: congestionData.conclusionRedFirstSecondaryButton,

        conclusionLowerText: setReplaceText(
          congestionData.conclusionRedLowerText,
          replaceTextParameters
        ),
        conclusionSecondPrimaryButton: congestionData.conclusionRedSecondPrimaryButton,
        conclusionSecondSecondaryButton: congestionData.conclusionRedSecondSecondaryButton,
      };
    case 'Yellow':
      return {
        feedbackTitle: setReplaceText(congestionData.feedbackYellowTitle, replaceTextParameters),
        feedbackText: setReplaceText(congestionData.feedbackYellowText, replaceTextParameters),
        feedbackPrimaryButton: congestionData.feedbackYellowPrimaryButton,
        feedbackSecondaryButton: congestionData.feedbackYellowSecondaryButton,

        disclaimer: disclaimerText,

        additionalInfoBlockTitle: setReplaceText(
          congestionData.additionalInfoBlockYellowTitle,
          replaceTextParameters
        ),
        additionalInfoBlockText: setReplaceText(
          congestionData.additionalInfoBlockYellowText,
          replaceTextParameters
        ),
        additionalInfoBlockPrimaryButton: congestionData.additionalInfoBlockYellowPrimaryButton,
        additionalInfoBlockSecondaryButton: congestionData.additionalInfoBlockYellowSecondaryButton,

        conclusionUpperText: setReplaceText(
          congestionData.conclusionYellowUpperText,
          replaceTextParameters
        ),
        conclusionFirstPrimaryButton: congestionData.conclusionYellowFirstPrimaryButton,
        conclusionFirstSecondaryButton: congestionData.conclusionYellowFirstSecondaryButton,

        conclusionLowerText: setReplaceText(
          congestionData.conclusionYellowLowerText,
          replaceTextParameters
        ),
        conclusionSecondPrimaryButton: congestionData.conclusionYellowSecondPrimaryButton,
        conclusionSecondSecondaryButton: congestionData.conclusionYellowSecondSecondaryButton,
      };
    case 'GridFull':
      return {
        feedbackTitle: setReplaceText(congestionData.feedbackGridFullTitle, replaceTextParameters),
        feedbackText: setReplaceText(congestionData.feedbackGridFullText, replaceTextParameters),
        feedbackPrimaryButton: congestionData.feedbackGridFullPrimaryButton,
        feedbackSecondaryButton: congestionData.feedbackGridFullSecondaryButton,

        disclaimer: disclaimerText,

        additionalInfoBlockTitle: setReplaceText(
          congestionData.additionalInfoBlockGridFullTitle,
          replaceTextParameters
        ),
        additionalInfoBlockText: setReplaceText(
          congestionData.additionalInfoBlockGridFullText,
          replaceTextParameters
        ),
        additionalInfoBlockPrimaryButton: congestionData.additionalInfoBlockGridFullPrimaryButton,
        additionalInfoBlockSecondaryButton:
          congestionData.additionalInfoBlockGridFullSecondaryButton,

        conclusionUpperText: setReplaceText(
          congestionData.conclusionGridFullUpperText,
          replaceTextParameters
        ),
        conclusionFirstPrimaryButton: congestionData.conclusionGridFullFirstPrimaryButton,
        conclusionFirstSecondaryButton: congestionData.conclusionGridFullFirstSecondaryButton,

        conclusionLowerText: setReplaceText(
          congestionData.conclusionGridFullLowerText,
          replaceTextParameters
        ),
        conclusionSecondPrimaryButton: congestionData.conclusionGridFullSecondPrimaryButton,
        conclusionSecondSecondaryButton: congestionData.conclusionGridFullSecondSecondaryButton,
      };
    case 'None':
      return {
        feedbackTitle: setReplaceText(congestionData.feedbackNoneTitle, replaceTextParameters),
        feedbackText: setReplaceText(congestionData.feedbackNoneText, replaceTextParameters),
        feedbackPrimaryButton: congestionData.feedbackNonePrimaryButton,
        feedbackSecondaryButton: congestionData.feedbackNoneSecondaryButton,

        disclaimer: disclaimerText,

        additionalInfoBlockTitle: setReplaceText(
          congestionData.additionalInfoBlockNoneTitle,
          replaceTextParameters
        ),
        additionalInfoBlockText: setReplaceText(
          congestionData.additionalInfoBlockNoneText,
          replaceTextParameters
        ),
        additionalInfoBlockPrimaryButton: congestionData.additionalInfoBlockNonePrimaryButton,
        additionalInfoBlockSecondaryButton: congestionData.additionalInfoBlockNoneSecondaryButton,

        conclusionUpperText: setReplaceText(
          congestionData.conclusionNoneUpperText,
          replaceTextParameters
        ),
        conclusionFirstPrimaryButton: congestionData.conclusionNoneFirstPrimaryButton,
        conclusionFirstSecondaryButton: congestionData.conclusionNoneFirstSecondaryButton,

        conclusionLowerText: setReplaceText(
          congestionData.conclusionNoneLowerText,
          replaceTextParameters
        ),
        conclusionSecondPrimaryButton: congestionData.conclusionNoneSecondPrimaryButton,
        conclusionSecondSecondaryButton: congestionData.conclusionNoneSecondSecondaryButton,
      };
    case 'NotFound':
      return {
        feedbackTitle: congestionData.errorTitle
          ? setReplaceText(congestionData.errorTitle, replaceTextParameters)
          : 'Liander niet uw netbeheerder',
        feedbackText: congestionData.errorMessage
          ? setReplaceText(congestionData.errorMessage, replaceTextParameters)
          : 'We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of zoek uw netbeheerder op via <a href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">mijnaansluiting.nl</a>',
      };
    default:
      return {
        feedbackTitle: congestionData.errorTitle || 'Er is iets fout gegaan',
        feedbackText:
          congestionData.errorMessage || 'Controleer uw gegevens en probeer het opnieuw.',
      };
  }
};
