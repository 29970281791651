import { AnchorHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import ReactIs from 'react-is';
import React from 'react';
import { button } from '../Button/Button.css';
import { ButtonProps } from '../Button';
import { AsChild } from '../Slot';
import { ToneFocus } from '../ToneFocusProvider';
type AnchorProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href' | 'target' | 'className' | 'onClick'
>;

export type ButtonLinkProps = AsChild & {
  href?: AnchorProps['href'];
  target?: AnchorProps['target'];
  className?: AnchorProps['className'];
  onClick?: AnchorProps['onClick'];
} & ButtonProps;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      beforeIcon,
      afterIcon,
      href,
      target,
      size = 'large',
      tone = 'onLight',
      variant = 'primary',
      asChild,
      className = '',
      onClick,
    },
    ref
  ) => {
    const linkProps = {
      ...(ref && { ref }),
      ...(href && { href }),
      ...(target && { target }),
      rel: target === '_blank' ? 'noopener noreferrer' : undefined,
    };
    const recipeClassNames = button({ size, variant });
    if (!children || typeof children === 'boolean') return null;

    const text =
      asChild && ReactIs.isElement(children)
        ? (children.props.children as React.ReactNode)
        : children;

    // We always replace the children, so we can add our own markup and icons
    if (ReactIs.isElement(children) && asChild) {
      return (
        <ToneFocus tone={tone}>
          {React.cloneElement(children, {
            ...children.props,
            children: (
              <>
                {beforeIcon}
                {text}
                {afterIcon}
              </>
            ),
            ...linkProps,
            className: clsx(
              children.props.className,
              recipeClassNames,
              className
            ),
            onClick: onClick,
          })}
        </ToneFocus>
      );
    }

    // Fallback
    return (
      <ToneFocus tone={tone}>
        <a
          {...linkProps}
          className={clsx(recipeClassNames, className)}
          onClick={onClick}
        >
          {beforeIcon}
          {text}
          {afterIcon}
        </a>
      </ToneFocus>
    );
  }
);
