import React from 'react';

import { FilteredOutageHeroProps, TopTasks } from 'components/OutageHero';
import { useParams } from 'src/hooks/useParams';
import { OutageSpecific } from './_OutageSpecific';
import { OutageGeneral } from './_OutageGeneral';

export type Props = FilteredOutageHeroProps & { topTasks: TopTasks; noOutageTopTasks: TopTasks };

// This is a pretty complex component, so we've decided to split it up into multiple layers to increase legibility, maintainability and testability.
export function OutageHeroComponent(props: Props) {
  const params = useParams();
  const { postalCode = '', reference, houseNumber, houseNumberAddition } = params;

  const isSpecific = Boolean(!reference && postalCode && houseNumber);

  return isSpecific ? (
    <OutageSpecific
      postalCode={postalCode}
      houseNumber={houseNumber}
      addition={houseNumberAddition}
      {...props}
    />
  ) : (
    <OutageGeneral {...props} />
  );
}
