import {
  capitalizeLocationName,
  firstLetterToUpperCase,
  formatCityListIntl,
  formatPostalCode,
  setReplaceText,
} from './format';

/**
 *
 * @param text The string that contains the placeholders
 * @param data The placeholders will be replaced with the values in this object
 * ** @param data.type "gas" or "elektriciteit"
 * ** @param data.postalCode The postal code
 * ** @param data.places "ROTTERDAM" or "AMSTERDAM;UTRECHT;ROTTERDAM" when multiple
 */
type TextData = Partial<Record<'type' | 'postalCode' | 'places', string>>;
export function outageFormatTexts(text: string, data: TextData) {
  const { type = '', postalCode = '', places = '' } = data;

  const value = setReplaceText(text, {
    '{type}': type,
    '{postalCode}': formatPostalCode(postalCode),
    '{places}': formatCityListIntl(places.split(';').map(capitalizeLocationName)),
  });

  return firstLetterToUpperCase(value);
}
