import React from 'react';
import clsx from 'clsx';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Box } from '../Box';
import { mq } from '../../global/breakpoints';
import { useMedia } from '../../hooks/useMedia';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './SubpageHero.css';

export const SubpageHeroComponent = (props: SubpageHeroProps) => (
  <SubpageHeroComponentView {...props} />
);

export const SubpageHeroSitecore = (props: SubpageHeroSitecoreProps) => {
  return <SubpageHeroComponentView {...props} />;
};

const SubpageHeroComponentView = (props: SubpageHeroViewProps) => {
  const isDesktop = useMedia(mq.md);
  const hasExplanation = !!props.explanation;

  return isDesktop ? (
    <PageGrid className={clsx('ads-subpageHero', styles.heroWrapper)}>
      {props.text ? (
        <GridItem
          columnStart={{ initial: '1', md: hasExplanation ? '1' : '3' }}
          columnEnd={{ initial: '-1', md: hasExplanation ? 'span 6' : '-3' }}
        >
          {props.text}
        </GridItem>
      ) : null}
      {hasExplanation ? (
        <GridItem
          columnStart={{ initial: '1', md: 'span 5', lg: 'span 4' }}
          columnEnd={{ initial: '-1', md: '-1' }}
          asChild
        >
          {props.explanation}
        </GridItem>
      ) : null}

      {props.image ? (
        <GridItem columnStart="1" columnEnd="-1">
          <Box
            width="100%"
            height="100%"
            paddingRight={{ initial: 0, md: 10 }}
            className={clsx(styles.image, styles.ImageWrapper)}
            asChild
          >
            {props.image}
          </Box>
        </GridItem>
      ) : null}

      {props.tasks ? (
        <GridItem
          columnStart="1"
          columnEnd="-1"
          className={styles.TopTaskSection}
        >
          {props.tasks}
        </GridItem>
      ) : null}
    </PageGrid>
  ) : (
    <div>
      <PageGrid className={styles.heroWrapper}>
        {props.text ? (
          <GridItem
            columnStart={{ initial: '1', md: hasExplanation ? '1' : '3' }}
            columnEnd={{ initial: '-1', md: hasExplanation ? 'span 6' : '-3' }}
          >
            <Box width="100%" asChild>
              {props.text}
            </Box>
          </GridItem>
        ) : null}
        {hasExplanation ? (
          <GridItem
            columnStart={{ initial: '1', md: 'span 4' }}
            columnEnd={{ initial: '-1', md: '-1' }}
          >
            <Box width="100%" asChild>
              {props.explanation}
            </Box>
          </GridItem>
        ) : null}
      </PageGrid>

      {props.image ? (
        <Box className={styles.ImageWrapper} width="100vw">
          <Box width="100%" height="100%" asChild className={styles.image}>
            {props.image}
          </Box>
        </Box>
      ) : null}

      {props.tasks ? (
        <PageGrid>
          <GridItem
            columnStart="1"
            columnEnd="-1"
            className={styles.TopTaskSection}
          >
            {props.tasks}
          </GridItem>
        </PageGrid>
      ) : null}
    </div>
  );
};

type SubpageHeroProps = {
  image?: React.ReactElement;
  text?: React.ReactElement;
  tasks?: React.ReactElement;
  explanation?: string;
};

type SubpageHeroSitecoreProps = {
  text?: React.ReactElement;
  image?: React.ReactElement;
  tasks?: React.ReactElement;
  explanation?: React.ReactElement;
};

type SubpageHeroViewProps = CombineProps<
  SubpageHeroProps,
  SubpageHeroSitecoreProps
>;
