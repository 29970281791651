import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHN4k7lPWl8eif1BIouQ4cWxHcrJOiv57QW3cQkNOgcaIYJ+5HY7mkCz+kONeHaUS8OcvAHd3t9/SwwK/Y4nu2wxHD4vt9DfHlYdFiXLj1nNce1gI4ap7NceNh4XeStSrOW6ZPI6pWzJ4xeA1k7+ZeBrj7BxvGf6Byd8xeM/gO6bPDwyfPcP/kcnzxOQ5MOt6ZngeGfzE4GcGf2HwgeE/Mv4Xxv+V8X9j8B9MnquHRW/iZ46/R1y2WvRz/MPDQtZNW7VzXAqmsIwK295v9VYQAzKvTKp/uCoao3mT4QhYjoBjeiJLZvhkVNtmtV5viJpllJtdycqWxBD1ZlbSmIoYWmZjkIGZWBklt642qy019FOELcsNMex+dnjlaEs41ckoO7f+rhVlFXW3MVuxpguMwutC77pADFF5neltTw1Ren3f646MnIza60MXklQnriVnbh0vXMQwDUPlJO1VFOBauErQXl08XIb6OJ7roTteiPGVG6G3/4r6wY3qlRu8d25aPpiFomBag5weEZniqBi6qLnD0zBdRssZOC0id/BhxdGNWsTOBYXEMJ19WJZBEkM7zbBtBY2IWgwKeyS7IHaMTjBq8V4JIWh3dx7E+QpSxGcZH8OuqX9Vbgkol6CWIAqBX5eTl5aFc86er6BUoRFl3l1q5Zz8jEEslLIq1nBFaSsmRFaItxCJhUFpJ1qFkA6ZmE93W1gjIhVTKBSizHuLUjhX2hurwlmHdvqGUpXMSoSxiJX8Spr24GF/HLsLiOkTg/NTss84Zqfm0U/G8XTYBybX08wlm+XgIfYq1ONDFxg5Pc99siI9etDnKwFP7BmH/942EtMLJ4eB2yFGTnIXZrPBV64Gd+tA7tqBV2a7xXdOWR+MslTc6ZoQqqYjhrjT1W1oeiLr6XLfNwFrcomZbve9aroklZ7US0AzvWEC2swrjbf6MgFLDyLCt39irqL5pzhvD+JRezDiCwGbHNM2RyrkSHUeZLqo3ufq7zxgWuvBA6bF9h4wrfboQaXVnjyotNrBg06rPXvQad6jB5NmOHmwKbOzB5dyePHTJk3QwUOV5h1jz1LnS2xaCr/GrqXwm4ffh9N1/1zDb6e3egn3w74+LGGsj+Pd2A17cgTFG/3/C7h6sIl43mNXKfiRa6oWHmRBjk4tc29FY85V5V621tGVbE3a5KZF25yry42bLnOulYd4khK0zrk2HmRKoI2u5NaqQ04busu5RhmlXHefrn/9DXoeySOlEAAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VXTW/iMBC98yusSkhEqlESCLTmsoc97nEPe1uZ2AG3wY4c00JX/PdVnC87cSAg9UbIfLw382bszL/25wWlPvg3AYCwPEvxGQEuON1MAPhkRO0RCKNp8bSnbLdXCAR+diqet0ISKqESGQJhdgIE53tKgJKY5xmWlKvCKhM5U0xwBPA2F+lR6cjaaeVPN5PLZF5hmNMPyjUQWSfqGAhC9PuUJq7X8TFX4kDlTw3kF+NUW1c4Y5EKicAHljMI/wY5W/BgERDvRgi0pYmQ9Blcs8GJorJMhuP3nRRHTkYmFIcM8/M9kF9uRHAg7pncAbiTrmhSlUH7x4IryhUCT0+GZGqNDGhGYsKOOQKRPx3WiKWCSjK+/lUoLBHygMqfKVZ0FvnTZwAjf+oVJl+QcUJPCERdARnUvw+6KdCbyOEI6IEG3CaTNMWKfehkByx3jMNtKuJ3mCssVYGuAN26X+nY0NTfV4eScYzTeFYTcZH9M2t4uivXsA+aysHQ5tIMoEnqvrkL6pG4K4Q1CWGnIw7l2u4L4jWUHG8uk4bXb7wbB4iagBaPc1k8vA+WDwCNHvBZXdF/o1frOFgND3qr6GUp6fqcswReKto42Bx1uD7/fivhha3h9Vg6nemmnPTE82bWaT2GdRn420kH9g5aI672MN6zlMwEIV5zPpkvitPF0+hdW803wr2gFOeqdDNId7ZZY/6q3yaCK5izL4pAsCqJ2wvHvdyqUkWhvRHrZ3c5ylBlGUAuUtYrZqCL+XbMFUvOsGlXTLmi0lrNSUp1JpyyHYdM0UNu2jkHKNTRNePPCvDK912NGnk0NbXEDyyK7QNbOh7nw7TPjwMlDIM8lpRygDkBswPjsJmmcJmdSmXNzTuvUWQttaIUl9bIOjhv2K4qowwTwvgOujZ95nWdDO12T1EAQZCd+h4WpM6Uj+yLNnRqvX/gO2BFFawh7dcX/HUdoXOnsG85Fr11xavyWJaoTIPOHkFcqBlKmKy3gVdFcG0QGPgVhIGI7QJqAjCeMk7rCPioxObe+GaPb/Yn8Lp9uF2DvohanNVtRLcPhpEpK3MBXCvIkEjHhRtY1a5xMpZrj/SrrYzVS62l2qX+x/AhlU/7vWlUxN846zXi88LIQJ0Zxlz0L5PLf3YUmSJ/DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';