/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FastEndpointsErrorResponse,
  IntegrationApiFeaturesCompensationContractsRequestsSaveCompensationRequest,
  IntegrationApiFeaturesCompensationContractsResponsesSaveCompensationResponse,
  IntegrationApiFeaturesCongestionContractsResponsesGetCongestionsResponse,
  IntegrationApiFeaturesCongestionContractsResponsesGetSmallScaleCongestionsResponse,
  IntegrationApiFeaturesCongestionEndpointsGetCongestionsEndpointParams,
  IntegrationApiFeaturesCongestionEndpointsGetSmallScaleCongestionsEndpointParams,
  IntegrationApiFeaturesContentServerContractsRequestsPostFileRequest,
  IntegrationApiFeaturesContentServerContractsResponsesPostFileResponse,
  IntegrationApiFeaturesCRMContractsRequestsCrmRequest,
  IntegrationApiFeaturesCRMContractsResponsesCrmResponse,
  IntegrationApiFeaturesExStreamContractsRequestsCreateMailRequest,
  IntegrationApiFeaturesExStreamContractsResponsesCreateMailResponse,
  IntegrationApiFeaturesFormGatewayContractsRequestsPostFormAttachmentRequest,
  IntegrationApiFeaturesFormGatewayContractsRequestsPostFormRequest,
  IntegrationApiFeaturesFormGatewayContractsResponsesPostFormAttachmentResponse,
  IntegrationApiFeaturesFormGatewayContractsResponsesPostFormResponse,
  IntegrationApiFeaturesOutagesContractsDataOutage,
  IntegrationApiFeaturesOutagesContractsRequestsSubscribeSmsServiceRequest,
  IntegrationApiFeaturesOutagesContractsResponsesGetLocationsResponse,
  IntegrationApiFeaturesOutagesContractsResponsesGetOutagesOnLocationResponse,
  IntegrationApiFeaturesOutagesContractsResponsesSubscribeSmsServiceResponse,
  IntegrationApiFeaturesOutagesEndpointsGetOutagesOnLocationEndpointParams,
  IntegrationApiFeaturesPostcodeSearchContractsResponsesGetAddressResponse,
  IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse,
  IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityResponse,
  SystemNetHttpStatusCode,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesPostcodeSearchEndpointsGetAddressEndpoint
   * @request GET:/api/address/{postalCode}/{houseNumber}
   */
  postcodeSearchEndpointsGetAddress = (
    {
      postalCode,
      houseNumber,
    }: { postalCode: string | null; houseNumber: number },
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesPostcodeSearchContractsResponsesGetAddressResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/address/${postalCode}/${houseNumber}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesOutagesEndpointsGetLocationsOfResolvedOutagesEndpoint
   * @request GET:/api/outages/resolved/locations
   */
  outagesEndpointsGetLocationsOfResolvedOutages = (
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesOutagesContractsResponsesGetLocationsResponse,
      any
    >({
      path: `/api/outages/resolved/locations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesOutagesEndpointsGetLocationsOfUnresolvedOutagesEndpoint
   * @request GET:/api/outages/unresolved/locations
   */
  outagesEndpointsGetLocationsOfUnresolvedOutages = (
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesOutagesContractsResponsesGetLocationsResponse,
      any
    >({
      path: `/api/outages/unresolved/locations`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesOutagesEndpointsGetOutagesOnLocationEndpoint
   * @request GET:/api/outages/on-location/{location}
   */
  outagesEndpointsGetOutagesOnLocation = (
    {
      location,
      ...query
    }: IntegrationApiFeaturesOutagesEndpointsGetOutagesOnLocationEndpointParams,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesOutagesContractsResponsesGetOutagesOnLocationResponse,
      any
    >({
      path: `/api/outages/on-location/${location}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesOutagesEndpointsGetSpecificOutageEndpoint
   * @request GET:/api/outages/specific-outage/{outageNumber}
   */
  outagesEndpointsGetSpecificOutage = (
    { outageNumber }: { outageNumber: string | null },
    params: RequestParams = {}
  ) => {
    return this.request<IntegrationApiFeaturesOutagesContractsDataOutage, any>({
      path: `/api/outages/specific-outage/${outageNumber}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesOutagesEndpointsSubscribeSmsServiceEndpoint
   * @request POST:/api/outages/subscribe/sms-service
   */
  outagesEndpointsSubscribeSmsService = (
    data: IntegrationApiFeaturesOutagesContractsRequestsSubscribeSmsServiceRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesOutagesContractsResponsesSubscribeSmsServiceResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/outages/subscribe/sms-service`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesFormGatewayEndpointsGetHealthStatusEndpoint
   * @request GET:/api/form-gateway/health
   */
  formGatewayEndpointsGetHealthStatus = (params: RequestParams = {}) => {
    return this.request<SystemNetHttpStatusCode, any>({
      path: `/api/form-gateway/health`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesFormGatewayEndpointsPostFormAttachmentEndpoint
   * @request POST:/api/post/form-attachment/{formId}
   */
  formGatewayEndpointsPostFormAttachment = (
    { formId }: { formId: string | null },
    data: IntegrationApiFeaturesFormGatewayContractsRequestsPostFormAttachmentRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesFormGatewayContractsResponsesPostFormAttachmentResponse,
      any
    >({
      path: `/api/post/form-attachment/${formId}`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesFormGatewayEndpointsPostFormEndpoint
   * @request POST:/api/post/form
   */
  formGatewayEndpointsPostForm = (
    data: IntegrationApiFeaturesFormGatewayContractsRequestsPostFormRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesFormGatewayContractsResponsesPostFormResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/post/form`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesExStreamEndpointsCreateMailEndpoint
   * @request POST:/api/create/mail
   */
  exStreamEndpointsCreateMail = (
    data: IntegrationApiFeaturesExStreamContractsRequestsCreateMailRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesExStreamContractsResponsesCreateMailResponse,
      any
    >({
      path: `/api/create/mail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesServiceAvailabilityEndpointsCrmEndpoint
   * @request POST:/api/crm
   */
  serviceAvailabilityEndpointsCrm = (
    data: IntegrationApiFeaturesCRMContractsRequestsCrmRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesCRMContractsResponsesCrmResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/crm`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesServiceAvailabilityEndpointsGetServiceAvailabilityDetailsEndpoint
   * @request GET:/api/service-availability/details/{postalCode}/{houseNumber}/{addition}
   */
  serviceAvailabilityEndpointsGetServiceAvailabilityDetails = (
    {
      postalCode,
      houseNumber,
      addition,
    }: {
      postalCode: string | null;
      houseNumber: number;
      addition: string | null;
    },
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse,
      any
    >({
      path: `/api/service-availability/details/${postalCode}/${houseNumber}/${addition}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesServiceAvailabilityEndpointsGetServiceAvailabilityEndpoint
   * @request GET:/api/service-availability/{postalCode}/{houseNumber}/{addition}
   */
  serviceAvailabilityEndpointsGetServiceAvailability = (
    {
      postalCode,
      houseNumber,
      addition,
    }: {
      postalCode: string | null;
      houseNumber: number;
      addition: string | null;
    },
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/service-availability/${postalCode}/${houseNumber}/${addition}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesContentServerEndpointsPostFileEndpoint
   * @request POST:/api/post/file
   */
  contentServerEndpointsPostFile = (
    data: IntegrationApiFeaturesContentServerContractsRequestsPostFileRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesContentServerContractsResponsesPostFileResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/post/file`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesCongestionEndpointsGetCongestionsEndpoint
   * @request GET:/api/congestions
   */
  congestionEndpointsGetCongestions = (
    query: IntegrationApiFeaturesCongestionEndpointsGetCongestionsEndpointParams,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesCongestionContractsResponsesGetCongestionsResponse,
      any
    >({
      path: `/api/congestions`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesCongestionEndpointsGetHealthStatusSmallScaleCongestionsEndpoint
   * @request GET:/api/congestions/small-scale/health
   */
  congestionEndpointsGetHealthStatusSmallScaleCongestions = (
    params: RequestParams = {}
  ) => {
    return this.request<SystemNetHttpStatusCode, any>({
      path: `/api/congestions/small-scale/health`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesCongestionEndpointsGetSmallScaleCongestionsEndpoint
   * @request GET:/api/congestions/small-scale
   */
  congestionEndpointsGetSmallScaleCongestions = (
    query: IntegrationApiFeaturesCongestionEndpointsGetSmallScaleCongestionsEndpointParams,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesCongestionContractsResponsesGetSmallScaleCongestionsResponse,
      any
    >({
      path: `/api/congestions/small-scale`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  };

  /**
   * No description
   *
   * @tags Api
   * @name IntegrationApiFeaturesCompensationEndpointsSaveCompensationEndpoint
   * @request POST:/api/compensation
   */
  compensationEndpointsSaveCompensation = (
    data: IntegrationApiFeaturesCompensationContractsRequestsSaveCompensationRequest,
    params: RequestParams = {}
  ) => {
    return this.request<
      IntegrationApiFeaturesCompensationContractsResponsesSaveCompensationResponse,
      FastEndpointsErrorResponse
    >({
      path: `/api/compensation`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  };
}
