import React from 'react';
import {
  Box,
  Dialog as AdsDialog,
  Heading as AdsHeading,
  Loader,
  RichText,
  Stack,
  Text,
} from '@ads-core/components';
import { CheckmarkIcon, CloseIcon } from '@ads-core/icons';

type DialogNetworkProps = {
  children?: React.ReactNode;
};

const Dialog = ({ children }: DialogNetworkProps) => {
  return (
    <AdsDialog>
      <Box
        width="100%"
        height="100%"
        paddingTop={{ initial: 10, md: 30 }}
        paddingRight={{ initial: 30, md: 20 }}
        paddingLeft={{ initial: 6, md: 20 }}
        paddingBottom={{ initial: 12, md: 24 }}
        asChild
      >
        <Stack gap={10}>{children}</Stack>
      </Box>
    </AdsDialog>
  );
};

const Loading = () => {
  return (
    <AdsHeading size="h3" as="h2">
      Laden... <Loader />
    </AdsHeading>
  );
};

export type DialogAddressProps = {
  address?: {
    postalCode: string;
    houseNumber: string;
    houseNumberAddition?: string;
  };
  modalTag?: string;
};

const Address = ({ address, modalTag }: DialogAddressProps) => {
  if (!address) return;

  const { postalCode, houseNumber, houseNumberAddition } = address;

  return (
    <Stack direction="row" gap={1}>
      <Text>{modalTag}</Text>
      <Text weight="bold">{postalCode},</Text>
      <Text>nr.</Text>
      <Text weight="bold">
        {houseNumber}
        {houseNumberAddition ? `-${houseNumberAddition}` : null}
      </Text>
    </Stack>
  );
};

export type DialogHeadingProps = {
  children?: React.ReactNode;
};

const Heading = ({ children }: DialogHeadingProps) => {
  return (
    <AdsHeading size="h3" as="h2">
      {children}
    </AdsHeading>
  );
};

export type DialogStatusProps = {
  title: string;
  message?: string;
  available?: boolean;
  status?: number;
};

const Status = (props: DialogStatusProps) => {
  const { available, title, message } = props;

  return (
    <Stack direction="row" alignY="start" gap={2}>
      <DialogNetworkStatusIcon available={available} />
      <Stack gap={2}>
        <AdsHeading fontWeight="semiBold" size="paragraph" as="h3" isResponsive={false}>
          {title}
        </AdsHeading>
        {message ? <RichText>{message}</RichText> : null}
      </Stack>
    </Stack>
  );
};

const DialogNetworkStatusIcon = ({ available }: Pick<DialogStatusProps, 'available'>) => {
  if (typeof available === 'undefined') return;

  return available ? <CheckmarkIcon color="alertSuccess" /> : <CloseIcon color="alertError" />;
};

export default { Status, Dialog, Loading, Heading, Address };
