import React from 'react';
import type { SitecoreForm } from '@sitecore-jss/sitecore-jss-forms';
import { Stack, PageGrid, GridItem, Box } from '@ads-core/components';
import { ComponentRendering, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { useAdsContext } from '@ads-core/providers';

import { ComponentProps } from 'src/jss/lib/component-props';
import 'src/components/Form/styles/Form.css';
import { Form } from 'src/components/Form';
import { scrollToElement } from '@ads-core/utils';
import { useSitecoreContext } from '@liander/context';
import { LayoutProps } from 'src/pages/_app';
import { useQuery } from '@tanstack/react-query';

const FormComponent = ({ fields }: { fields: SitecoreForm } & ComponentProps) => {
  const refPageGrid = React.useRef<HTMLDivElement>(null);
  const adsContext = useAdsContext();

  const { context } = useSitecoreContext();
  const itemPath = context?.itemPath;
  const language = context?.language;
  const site = context?.site;
  /**
   * Sitecore needs a http-only cookie which doesnt get set on request time with SSG
   * So we need to fetch the form data clientside from Sitecore first to get the cookie
   * But because the http-only cookie is coupled to the anti-forgery token and form session id
   * we need to insert these values into the form data before submitting the form.
   *
   * For ease of use we just replace the whole fields property with the fetched form data
   */

  const uri = `/sitecore/api/layout/render/jss?item=${itemPath}&sc_lang=${language?.toLowerCase()}&sc_apikey=${
    process.env.NEXT_PUBLIC_SITECORE_API_KEY
  }&sc_site=${site?.name ?? ''}`;

  const query = useQuery({
    initialData: fields,
    refetchOnWindowFocus: false,
    queryKey: [itemPath],
    queryFn: () =>
      fetch(uri, {
        method: 'GET',
        credentials: 'include',
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      }),
    select: (data: LayoutProps['layoutData']) => {
      return Object.values(data.sitecore.route.placeholders)
        .flat()
        .filter((c): c is ComponentRendering => 'componentName' in c)
        .find((c) => c.componentName === 'form')?.fields as SitecoreForm | undefined;
    },
  });

  return (
    <PageGrid ref={refPageGrid}>
      <GridItem columnEnd="-1" columnStart="1" rowStart="1" rowEnd="-1" asChild>
        <Box bg="backgroundLight" borderRadius="lg" paddingTop={20} paddingBottom={20} />
      </GridItem>

      <GridItem
        columnEnd={{ initial: '-1', md: '-3' }}
        columnStart={{ initial: '1', md: '3' }}
        rowStart="1"
        rowEnd="-1"
      >
        <Box
          paddingTop={{ initial: 8, md: 20 }}
          paddingBottom={{ initial: 8, md: 20 }}
          paddingLeft={{ initial: 8, md: 0 }}
          paddingRight={{ initial: 8, md: 0 }}
          width="100%"
        >
          <Stack gap={6} isFullWidth>
            <Form
              form={query.data ?? fields}
              handleOnSubmit={(status) => {
                if (status === 'nextForm' || status === 'success') {
                  scrollToElement(refPageGrid, adsContext.topHeaderHeight + 24);
                }
              }}
            />
          </Stack>
        </Box>
      </GridItem>
    </PageGrid>
  );
};

export default withDatasourceCheck()<ComponentProps>(FormComponent);
