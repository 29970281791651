/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IntegrationApiFeaturesPostcodeSearchContractsResponsesGetAddressResponse {
  city?: string;
  street?: string;
}

export interface FastEndpointsErrorResponse {
  /** @format int32 */
  statusCode?: number;
  message?: string;
  errors?: Record<string, string[]>;
}

export type IntegrationApiFeaturesPostcodeSearchContractsRequestsGetAddressRequest =
  object;

export interface IntegrationApiFeaturesOutagesContractsResponsesGetLocationsResponse {
  locations?: IntegrationApiFeaturesOutagesContractsDataLocationInfo[] | null;
}

export interface IntegrationApiFeaturesOutagesContractsDataLocationInfo {
  locationName?: string;
  /** @format int32 */
  amountOfOutages?: number;
}

export interface IntegrationApiFeaturesOutagesContractsResponsesGetOutagesOnLocationResponse {
  /** @format int32 */
  count?: number | null;
  outages?: IntegrationApiFeaturesOutagesContractsDataOutage[] | null;
}

export interface IntegrationApiFeaturesOutagesContractsDataOutage {
  /** @format int32 */
  outageNumber?: number | null;
  estimatedEndTime?: string | null;
  reportTime?: string | null;
  endDate?: string | null;
  endTime?: string | null;
  duration?: string | null;
  status?: string | null;
  cause?: string | null;
  energyType?: string | null;
  affectedCustomers?: string | null;
  affectedPostalCodes?: string | null;
  affectedStreets?: string | null;
  affectedPlaces?: string | null;
  rings?: number[][][] | null;
}

export type IntegrationApiFeaturesOutagesContractsRequestsGetOutagesOnLocationRequest =
  object;

export type IntegrationApiFeaturesOutagesContractsRequestsGetSpecificOutageRequest =
  object;

export interface IntegrationApiFeaturesOutagesContractsResponsesSubscribeSmsServiceResponse {
  success?: boolean;
  nrAlreadyExists?: boolean;
  tooManyAddresses?: boolean;
}

export interface IntegrationApiFeaturesOutagesContractsRequestsSubscribeSmsServiceRequest {
  /** @format int32 */
  houseNumber?: number;
  addition?: string | null;
  postalCode?: string;
  telephoneNumber?: string;
}

export enum SystemNetHttpStatusCode {
  Continue = 'continue',
  SwitchingProtocols = 'switchingProtocols',
  Processing = 'processing',
  EarlyHints = 'earlyHints',
  OK = 'ok',
  Created = 'created',
  Accepted = 'accepted',
  NonAuthoritativeInformation = 'nonAuthoritativeInformation',
  NoContent = 'noContent',
  ResetContent = 'resetContent',
  PartialContent = 'partialContent',
  MultiStatus = 'multiStatus',
  AlreadyReported = 'alreadyReported',
  IMUsed = 'imUsed',
  MultipleChoices = 'ambiguous',
  Ambiguous = 'ambiguous',
  MovedPermanently = 'moved',
  Moved = 'moved',
  Found = 'redirect',
  Redirect = 'redirect',
  SeeOther = 'redirectMethod',
  RedirectMethod = 'redirectMethod',
  NotModified = 'notModified',
  UseProxy = 'useProxy',
  Unused = 'unused',
  TemporaryRedirect = 'temporaryRedirect',
  RedirectKeepVerb = 'temporaryRedirect',
  PermanentRedirect = 'permanentRedirect',
  BadRequest = 'badRequest',
  Unauthorized = 'unauthorized',
  PaymentRequired = 'paymentRequired',
  Forbidden = 'forbidden',
  NotFound = 'notFound',
  MethodNotAllowed = 'methodNotAllowed',
  NotAcceptable = 'notAcceptable',
  ProxyAuthenticationRequired = 'proxyAuthenticationRequired',
  RequestTimeout = 'requestTimeout',
  Conflict = 'conflict',
  Gone = 'gone',
  LengthRequired = 'lengthRequired',
  PreconditionFailed = 'preconditionFailed',
  RequestEntityTooLarge = 'requestEntityTooLarge',
  RequestUriTooLong = 'requestUriTooLong',
  UnsupportedMediaType = 'unsupportedMediaType',
  RequestedRangeNotSatisfiable = 'requestedRangeNotSatisfiable',
  ExpectationFailed = 'expectationFailed',
  MisdirectedRequest = 'misdirectedRequest',
  UnprocessableEntity = 'unprocessableEntity',
  Locked = 'locked',
  FailedDependency = 'failedDependency',
  UpgradeRequired = 'upgradeRequired',
  PreconditionRequired = 'preconditionRequired',
  TooManyRequests = 'tooManyRequests',
  RequestHeaderFieldsTooLarge = 'requestHeaderFieldsTooLarge',
  UnavailableForLegalReasons = 'unavailableForLegalReasons',
  InternalServerError = 'internalServerError',
  NotImplemented = 'notImplemented',
  BadGateway = 'badGateway',
  ServiceUnavailable = 'serviceUnavailable',
  GatewayTimeout = 'gatewayTimeout',
  HttpVersionNotSupported = 'httpVersionNotSupported',
  VariantAlsoNegotiates = 'variantAlsoNegotiates',
  InsufficientStorage = 'insufficientStorage',
  LoopDetected = 'loopDetected',
  NotExtended = 'notExtended',
  NetworkAuthenticationRequired = 'networkAuthenticationRequired',
}

export interface IntegrationApiFeaturesFormGatewayContractsResponsesPostFormAttachmentResponse {
  success?: boolean;
  errorMessage?: string | null;
}

export interface IntegrationApiFeaturesFormGatewayContractsRequestsPostFormAttachmentRequest {
  /** @format binary */
  file?: File;
}

export interface IntegrationApiFeaturesFormGatewayContractsResponsesPostFormResponse {
  formId?: string;
  success?: boolean;
  errorMessage?: string | null;
}

export interface IntegrationApiFeaturesFormGatewayContractsRequestsPostFormRequest {
  onderwerp?: string;
  afdelingCategorie?: string;
  bijlagenBestandsnamen?: string[] | null;
  voornaam?: IntegrationApiFeaturesFormGatewayContractsDataAnswer;
  achternaam?: IntegrationApiFeaturesFormGatewayContractsDataAnswer;
  emailadres?: IntegrationApiFeaturesFormGatewayContractsDataAnswer;
  tussenvoegsel?: IntegrationApiFeaturesFormGatewayContractsDataAnswer | null;
  telefoonnummer?: IntegrationApiFeaturesFormGatewayContractsDataAnswer | null;
  kvknummer?: IntegrationApiFeaturesFormGatewayContractsDataAnswer | null;
  additionalProperties?: Record<
    string,
    IntegrationApiFeaturesFormGatewayContractsDataAnswer
  >;
}

export interface IntegrationApiFeaturesFormGatewayContractsDataAnswer {
  beschrijving?: string;
  waarde?: string;
}

export interface IntegrationApiFeaturesExStreamContractsResponsesCreateMailResponse {
  success?: boolean;
  errorMessage?: string | null;
}

export interface IntegrationApiFeaturesExStreamContractsRequestsCreateMailRequest {
  content?: IntegrationApiFeaturesExStreamContractsDataContent;
}

export interface IntegrationApiFeaturesExStreamContractsDataContent {
  contentType?: string;
  data?: string;
}

export interface IntegrationApiFeaturesCRMContractsResponsesCrmResponse {
  success?: boolean;
  errorMessage?: string | null;
}

export interface IntegrationApiFeaturesCRMContractsRequestsCrmRequest {
  /** @minLength 1 */
  processID: string;
  /** @minLength 1 */
  subject: string;
  teamEmail?: string | null;
  teamNummer?: string | null;
  qualifiers?: IntegrationApiFeaturesCRMContractsDataQualifier[];
}

export interface IntegrationApiFeaturesCRMContractsDataQualifier {
  id?: string | null;
  name?: string | null;
  value?: string | null;
  valueMap?: IntegrationApiFeaturesCRMContractsDataValueMap | null;
}

export interface IntegrationApiFeaturesCRMContractsDataValueMap {
  filename?: string | null;
  content?: string | null;
}

export interface IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityDetailsResponse {
  address?: IntegrationApiFeaturesServiceAvailabilityContractsDataAddress;
  electricityNetwork?: IntegrationApiFeaturesServiceAvailabilityContractsDataUtilityNetwork;
  gasNetwork?: IntegrationApiFeaturesServiceAvailabilityContractsDataUtilityNetwork;
  /** @format int32 */
  outageNumber?: number | null;
}

export interface IntegrationApiFeaturesServiceAvailabilityContractsDataAddress {
  postalCode?: string | null;
  /** @format int32 */
  houseNumber?: number | null;
  street?: string | null;
  city?: string | null;
}

export interface IntegrationApiFeaturesServiceAvailabilityContractsDataUtilityNetwork {
  isOperational?: boolean | null;
  isHealthy?: boolean | null;
}

export type IntegrationApiFeaturesServiceAvailabilityContractsRequestsGetServiceAvailabilityDetailsRequest =
  object;

export interface IntegrationApiFeaturesServiceAvailabilityContractsResponsesGetServiceAvailabilityResponse {
  address?: IntegrationApiFeaturesServiceAvailabilityContractsDataAddress;
  isHealthy?: boolean;
  isOperational?: boolean;
}

export type IntegrationApiFeaturesServiceAvailabilityContractsRequestsGetServiceAvailabilityRequest =
  object;

export interface IntegrationApiFeaturesContentServerContractsResponsesPostFileResponse {
  success?: boolean;
  errorMessage?: string | null;
  /** @format int32 */
  id?: number;
}

export interface IntegrationApiFeaturesContentServerContractsRequestsPostFileRequest {
  /** @format int32 */
  type?: number;
  /** @format int32 */
  parentId?: number;
  /**
   * @format binary
   * @minLength 1
   */
  file: File;
}

export interface IntegrationApiFeaturesCongestionContractsResponsesGetCongestionsResponse {
  postalCode?: string;
  directions?: IntegrationApiFeaturesCongestionContractsDataDirections | null;
  success?: boolean;
  errorMessage?: string | null;
}

export interface IntegrationApiFeaturesCongestionContractsDataDirections {
  consume?: IntegrationApiFeaturesCongestionContractsDataDirection | null;
  supply?: IntegrationApiFeaturesCongestionContractsDataDirection | null;
}

export interface IntegrationApiFeaturesCongestionContractsDataDirection {
  highestLevel?: string | null;
  latestEndDate?: string | null;
  shortages?: IntegrationApiFeaturesCongestionContractsDataShortage[] | null;
}

export interface IntegrationApiFeaturesCongestionContractsDataShortage {
  highestLevel?: string | null;
  latestEndDate?: string | null;
  assetName?: string | null;
  causativeAssets?:
    | IntegrationApiFeaturesCongestionContractsDataCausativeAsset[]
    | null;
}

export interface IntegrationApiFeaturesCongestionContractsDataCausativeAsset {
  endDate?: string | null;
  name?: string | null;
  level?: string | null;
}

export type IntegrationApiFeaturesCongestionContractsRequestsGetCongestionsRequest =
  object;

export interface IntegrationApiFeaturesCongestionContractsResponsesGetSmallScaleCongestionsResponse {
  postalCode?: string;
  /** @format int32 */
  houseNumber?: number;
  addition?: string | null;
  level?: string;
  origin?: string;
  success?: boolean;
  errorMessage?: string | null;
  errorCode?: string | null;
}

export type IntegrationApiFeaturesCongestionContractsRequestsGetSmallScaleCongestionsRequest =
  object;

export interface IntegrationApiFeaturesCompensationContractsResponsesSaveCompensationResponse {
  success?: boolean;
  errorMessage?: string | null;
}

export interface IntegrationApiFeaturesCompensationContractsRequestsSaveCompensationRequest {
  /** @minLength 1 */
  customerNumber: string;
  /** @minLength 1 */
  compensationCode: string;
  /** @minLength 1 */
  iban: string;
  /** @minLength 1 */
  firstName: string;
  namePrefix?: string | null;
  /** @minLength 1 */
  lastName: string;
}

export interface IntegrationApiFeaturesOutagesEndpointsGetOutagesOnLocationEndpointParams {
  Resolved: boolean;
  ReturnCountOnly: boolean;
  /** @format int32 */
  Amount: number;
  /** @format int32 */
  Offset?: number | null;
  PostalCode?: string | null;
  EnergyType?: string | null;
  ReportDateFrom?: string | null;
  ReportDateTo?: string | null;
  location: string | null;
}

export interface IntegrationApiFeaturesCongestionEndpointsGetCongestionsEndpointParams {
  PostalCode: string | null;
}

export interface IntegrationApiFeaturesCongestionEndpointsGetSmallScaleCongestionsEndpointParams {
  PostalCode: string | null;
  /** @format int32 */
  HouseNumber: number;
  Addition?: string | null;
}
