import React from 'react';
import { TextField, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { DialogueSitecore, GridItem, PageGrid, ScrollLinkWrapper } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';

type DialogueTextProps = ComponentProps & {
  fields: {
    tag: TextField;
    text: TextField;
    button: LinkField;
  };
};

const DialogueText = (props: DialogueTextProps) => {
  const { text, tag, button } = props.fields;
  const isEditor = !!text.editable;

  const textElement = isEditor ? <SitecoreText field={text} editable /> : text.value?.toString();
  const tagElement = tag.value ? <SitecoreText field={tag} editable /> : undefined;

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <PageGrid>
        <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
          <DialogueSitecore tag={tagElement} text={textElement} button={renderJssLink(button)} />
        </GridItem>
      </PageGrid>
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(DialogueText);
