import React from 'react';
import { Checkbox, Stack } from '@ads-core/components';

type FilterEnergyTypeProps = {
  onChangeValue: (value: string[]) => void;
  value?: string[];
};

export const FilterEnergyType = ({ onChangeValue, value }: FilterEnergyTypeProps) => {
  const hasValue = Boolean(value && value.length);

  const onCheckChanged = (newValue: string) => {
    let filter: string[] = [];

    if (!value || !value.length) {
      filter = [newValue];
    }

    if (value && !value?.includes(newValue)) {
      filter = [...value, newValue];
    }

    if (value && value?.includes(newValue)) {
      filter = value.filter((filter) => filter !== newValue);
    }

    onChangeValue(filter);
  };

  return (
    <Stack gap={4}>
      <Checkbox
        label="Stroom"
        name="type"
        checked={hasValue && value?.includes('stroom')}
        onCheckedChange={() => {
          onCheckChanged('stroom');
        }}
      />
      <Checkbox
        label="Gas"
        name="type"
        checked={hasValue && value?.includes('gas')}
        onCheckedChange={() => {
          onCheckChanged('gas');
        }}
      />
    </Stack>
  );
};
