import { Heading, Stack, Box, ButtonLink, RichText } from '@ads-core/components';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { useTrackingContext } from '@liander/context';

export type CongestionMessageProps = {
  title?: string;
  description?: string;
  buttonPrimary?: ButtonProps;
  buttonSecondary?: ButtonProps;
};

export const CongestionMessage = ({
  title,
  description,
  buttonPrimary,
  buttonSecondary,
}: CongestionMessageProps) => {
  const { trackPageInteraction } = useTrackingContext();

  return (
    <Stack gap={8}>
      {title || description ? (
        <Stack gap={4}>
          {title ? (
            <Heading size="h5" as="h3">
              {title}
            </Heading>
          ) : null}
          {description ? <RichText>{description}</RichText> : null}
        </Stack>
      ) : null}
      {buttonPrimary || buttonSecondary ? (
        <Stack gap={4}>
          {buttonPrimary?.text ? (
            <Box width="100%" asChild>
              <ButtonLink
                variant="primary"
                onClick={() => {
                  trackPageInteraction({
                    actionDetail: buttonPrimary.text ?? '',
                    actionType: 'button_click',
                  });
                }}
                {...buttonPrimary}
              >
                {buttonPrimary.text}
              </ButtonLink>
            </Box>
          ) : null}
          {buttonSecondary?.text ? (
            <Box width="100%" asChild>
              <ButtonLink
                variant="ghost"
                onClick={() => {
                  trackPageInteraction({
                    actionDetail: buttonSecondary.text ?? '',
                    actionType: 'button_click',
                  });
                }}
                {...buttonSecondary}
              >
                {buttonSecondary.text}
              </ButtonLink>
            </Box>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};
