import { ButtonProps } from '@alliander-fe/sitecore-types';
import { ArrowRightIcon } from '../../icons';
import { Box } from '../Box';
import { ButtonLink } from '../ButtonLink';
import { RichText } from '../RichText';
import { Stack } from '../Stack';

export type ConclusionBlockProps = {
  topText?: string;
  bottomText?: string;
  firstPrimaryButton?: ButtonProps;
  firstSecondaryButton?: ButtonProps;
  secondPrimaryButton?: ButtonProps;
  secondSecondaryButton?: ButtonProps;
};

export const ConclusionBlock = ({
  topText,
  bottomText,
  firstPrimaryButton,
  firstSecondaryButton,
  secondPrimaryButton,
  secondSecondaryButton,
}: ConclusionBlockProps) => {
  return (
    <Box
      bg="containerPrimary"
      borderRadius="brandLg"
      padding={{ initial: 10, md: 24 }}
      paddingBottom={{ initial: 10, md: 20 }}
      paddingTop={{ initial: 10, md: 20 }}
      asChild
    >
      <Stack gap={10}>
        {topText ? <RichText tone="onDark">{topText}</RichText> : null}
        <ButtonConclusionBlock
          primaryButton={firstPrimaryButton}
          secondaryButton={firstSecondaryButton}
        />
        {bottomText ? <RichText tone="onDark">{bottomText}</RichText> : null}
        <ButtonConclusionBlock
          primaryButton={secondPrimaryButton}
          secondaryButton={secondSecondaryButton}
        />
      </Stack>
    </Box>
  );
};

export const ButtonConclusionBlock = ({
  primaryButton,
  secondaryButton,
}: {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
}) => {
  if (!primaryButton && !secondaryButton) return null;

  return (
    <Stack direction="row" wrap gap={4}>
      {primaryButton?.text ? (
        <ButtonLink
          variant="secondary"
          {...primaryButton}
          afterIcon={<ArrowRightIcon size="xs" />}
          asChild
        >
          {primaryButton.text}
        </ButtonLink>
      ) : null}
      {secondaryButton?.text ? (
        <ButtonLink
          variant="ghostOnDark"
          {...secondaryButton}
          afterIcon={<ArrowRightIcon size="xs" />}
          asChild
        >
          {secondaryButton.text}
        </ButtonLink>
      ) : null}
    </Stack>
  );
};
