import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { OutagesOverviewView } from 'src/components/OutagesOverview';

export type OutageTasksProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    title: { value: string };
    color: { value: string };
    link: { value: ButtonProps };
  };
};

type FollowUpStepProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    moreDetailsText: { value: string };
    moreDetailsTitle: { value: string };
    text: { value: string };
    title: { value: string };
    type: { value: string };
    moreDetailsButton: { value: ButtonProps };
    moreDetailsTag: { value: string };
    tag: { value: string };
  };
};

type FollowUpStepFieldProps = {
  followUpSteps: FollowUpStepProps[];
  tag: { value: string };
  title: { value: string };
};

export type FollowUpStepsProps = {
  displayName: string;
  fields: FollowUpStepFieldProps;
  id: string;
  name: string;
  url: string;
};

export type OutagesOverviewProps = {
  fields: {
    title: { value: string };
    parentOfOutageLocationWildcardPage: { value: ButtonProps };
    onlyShowResolvedOutages: { value: boolean };
  };
} & ComponentProps;

const OutagesOverview = (props: OutagesOverviewProps) => {
  return props?.fields ? <OutagesOverviewView {...props.fields} /> : null;
};

export default withDatasourceCheck()<OutagesOverviewProps>(OutagesOverview);
