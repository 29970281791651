import React from 'react';
import { isCongestionCheck } from 'src/utils';

import { Item } from '@sitecore-jss/sitecore-jss-nextjs';
import { PostalCodeCheckFormProps } from '@ads-core/types';

import { mapWithoutValueProperty } from 'src/utils/mapWithoutValueProperty';

import {
  FilteredCongestionCheckTasksProps,
  SiteCoreCongestionCheckProp,
} from '../CongestionCheckForm/';
import {
  FilteredNetworkCheckProps,
  NetworkCheckForm,
  SiteCoreNetworkCheckProps,
} from '../NetworkCheckForm';
import {
  FilteredOutageCheckProps,
  OutageCheckForm,
  SiteCoreOutageCheckProps,
} from '../OutageCheckForm';
import { CongestionCheckForm } from '../CongestionCheckForm';

export const SiteCoreCheckTask = ({
  color,
  ...checkTask
}: CheckTaskProps & { color?: PostalCodeCheckFormProps['color'] }) => {
  const mappedCheckTaskFields = mapWithoutValueProperty(checkTask.fields);

  if (checkTask && isCongestionCheck(checkTask)) {
    return (
      <CongestionCheckForm
        congestionCheck={mappedCheckTaskFields as FilteredCongestionCheckTasksProps}
        color={color}
      />
    );
  }

  // The Network is put in a FeatureToggle because we don't want it to be visible.
  // This object contains some features we can toggle within the NetworkCheck.
  const featureToggleNetworkCheck = mappedCheckTaskFields.enableNewFeedbackModal
    ? {
        congestionCheck: Boolean(mappedCheckTaskFields?.enableCongestionCheckAPICall),
        networkCheck: true,
        outageCheck: true,
      }
    : null;

  // @todo: find a way to not set the types as
  return featureToggleNetworkCheck ? (
    <NetworkCheckForm
      networkCheck={mappedCheckTaskFields as FilteredNetworkCheckProps}
      featureToggle={featureToggleNetworkCheck}
      color={color}
    />
  ) : (
    <OutageCheckForm
      outageCheck={mappedCheckTaskFields as FilteredOutageCheckProps}
      color={color}
    />
  );
};

export type FeatureToggleProps = {
  enableNewFeedbackModal: { value: boolean };
  enableCongestionCheckAPICall: { value: boolean };
};

export type CongestionProps = Item & { type: 'congestion'; fields: SiteCoreCongestionCheckProp };
export type NetworkProps = Item & {
  type: 'network';
  fields: SiteCoreNetworkCheckProps & SiteCoreOutageCheckProps & FeatureToggleProps;
};
export type CheckTaskProps = CongestionProps | NetworkProps;
