import React from 'react';
import clsx from 'clsx';
import { Box, BoxProps } from '../Box';
import { VisuallyHidden } from '../VisuallyHidden';

import * as styles from './Skeleton.css';

/**
 * Used https://adrianroselli.com/2020/11/more-accessible-skeletons.html as a reference for a11y
 */

interface SkeletonProps {
  children: React.ReactNode;
  label?: string;
}

const SkeletonGroup = ({
  label = 'Bezig met laden',
  children,
}: SkeletonProps) => {
  return (
    <div role="status" data-skeleton-group>
      <VisuallyHidden>{label}</VisuallyHidden>
      <div aria-hidden={true}>{children}</div>
    </div>
  );
};

type SkeletonItemProps = { label?: string } & Pick<
  BoxProps,
  'width' | 'height'
> &
  styles.ItemVariants;

/**
 * Skeleton.Item can be used on its own or in a Skeleton.Group.
 * When used in a Skeleton.Group, the Skeleton.Group will provide a11y
 */
const SkeletonItem = ({
  height,
  width,
  type,
  label = 'Bezig met laden',
}: SkeletonItemProps) => {
  const [isInGroup, setIsInGroup] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current && ref.current.closest('[data-skeleton-group]')) {
      setIsInGroup(true);
    }
  }, []);

  return (
    <Box
      ref={ref}
      className={clsx(styles.item({ type }), styles.skeleton)}
      width={width}
      height={height}
      bg="backgroundDisabled"
      asChild={!isInGroup}
    >
      {!isInGroup && (
        <div role="status">
          <VisuallyHidden>{label}</VisuallyHidden>
        </div>
      )}
    </Box>
  );
};

export const Skeleton = {
  Group: SkeletonGroup,
  Item: SkeletonItem,
};
