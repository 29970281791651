import React from 'react';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Heading } from '../Heading/index';
import { Tag } from '../Tag';
import { Stack } from '../Stack';

export type SimpleHeroProps = {
  tag?: string;
  title?: string;
  text?: string;
  alignment?: 'centered' | 'centeredLeft' | 'justified';
};

type SimpleHeroViewProps = {
  tag?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  alignment?: 'centered' | 'centeredLeft' | 'justified';
};

export const SimpleHeroSitecore = (props: SimpleHeroViewProps) => (
  <SimpleHeroView {...props} />
);

export const SimpleHero = (props: SimpleHeroProps) => (
  <SimpleHeroView {...props} />
);

export const SimpleHeroView = ({
  alignment = 'justified',
  tag,
  title,
  text,
}: SimpleHeroViewProps) => {
  const isCentered = alignment === 'centered' || alignment === 'centeredLeft';
  const isTextCentered = alignment === 'centered';
  const isJustified = alignment === 'justified';
  const isStartLeft = !alignment || alignment === 'justified';

  const justifiedWithText = isJustified && !!text ? '7' : '12';
  const firstColumnSpan = isJustified ? justifiedWithText : '8';

  if (!tag && !title && !text) {
    return null;
  }

  return (
    <div>
      <PageGrid
        alignX={{ md: isCentered ? 'center' : 'start' }}
        className="ads-simpleHero"
      >
        <GridItem
          columnStart={{ initial: '1', md: isStartLeft ? '1' : '3' }}
          columnEnd={{
            initial: '-1',
            md: `span ${firstColumnSpan}`,
          }}
        >
          <Stack
            gap={12}
            alignX={{
              initial: 'start',
              lg: isTextCentered ? 'center' : 'start',
            }}
          >
            {tag ? <Tag>{tag}</Tag> : null}
            <Heading
              size="h1"
              align={{
                initial: 'start',
                lg: isTextCentered ? 'center' : 'start',
              }}
            >
              {title}
            </Heading>
          </Stack>
        </GridItem>
        {text && isJustified ? (
          <GridItem
            columnStart={{ initial: '1', md: '9' }}
            columnEnd={{ initial: '-1' }}
          >
            {text}
          </GridItem>
        ) : (
          ''
        )}
      </PageGrid>
    </div>
  );
};
