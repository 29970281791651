import React from 'react';
import type { TextField, ImageFieldValue, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Box, InfoSectionSitecore, ScrollLinkWrapper } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreImage, SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';
import NextLink from 'next/link';

type Article = {
  fields: {
    tag: TextField;
    shortTitle: TextField;
    button: LinkField;

    infoImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
  };
};

type NewsArticle = {
  fields: {
    highlightButton: LinkField;
    highlightCardText: TextField;
    highlightCardTitle: TextField;
    highlightTitle: TextField;
    highlightCardButton: LinkField;
    highlightCardImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    seoType: LinkField;
    seoImage: LinkField;
    seoMetaDescription: LinkField;
    seoMetaKeywords: LinkField;
    seoMetaTitle: LinkField;
    pin: { value: boolean };
    pageButton: LinkField;
    body: TextField;
    imageText: TextField;
    introductionText: TextField;
    publicationDate: TextField;
    infoImage: {
      value: ImageFieldValue & { height: string; width: string; alt: string };
    };
    shortTitle: TextField;
    tag: TextField;
  };
};

type InfoSectionSitecoreProps = ComponentProps & {
  fields: {
    title: TextField;
    articles: Array<Article | NewsArticle>;
  };
  params?: {
    anchor?: string;
  };
};

const InfoSection = (props: InfoSectionSitecoreProps) => {
  const siteCoreArticles = props.fields.articles.map((article) => {
    const buttonTitle = article.fields.shortTitle.value?.toString();
    const button = isArticle(article) ? article.fields.button : article.fields.pageButton;

    return {
      title: renderJssLink(
        { ...button, value: { ...button.value, text: buttonTitle } },
        <Box position="relative" zIndex="1" as="span">
          {buttonTitle}
        </Box>
      ),
      tag: <SitecoreText field={article.fields.tag} editable tag="span" />,
      infoImage: <SitecoreImage field={article.fields.infoImage} editable />,
      link: button.value.href ? (
        <NextLink href={button.value.href} aria-label={button.value.text} />
      ) : undefined,
    };
  });

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <InfoSectionSitecore
        title={<SitecoreText field={props.fields.title} editable />}
        articles={siteCoreArticles}
      />
    </ScrollLinkWrapper>
  );
};

export function isArticle(article?: Article | NewsArticle): article is Article {
  return typeof article !== 'undefined' && 'button' in article.fields;
}

export default withDatasourceCheck()<ComponentProps>(InfoSection);
