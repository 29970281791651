/**
 * Transform cause if it's known otherwise it returns the same value.
 * @param cause
 * @returns 'Storing' | 'Onderhoud' | cause
 */
export function causeMapper(cause: string | null | undefined) {
  switch (cause) {
    case 'Spontane storing':
      return 'Storing';
    case 'onderhoud':
    case 'Geplande werkzaamheden':
      return 'Onderhoud';
    default:
      return cause;
  }
}
