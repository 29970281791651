export type FilterValuePropertyFromFields<T extends { [index: string]: unknown } | undefined> =
  T extends undefined
    ? never
    : {
        [K in keyof T]: Exclude<T[K], undefined> extends { value?: infer V } ? V : T[K];
      };

/**
 * Maps the the sitecore jss data object and replaces return the data without the value property.
 *
 * @param fields - The sitecore jss data:
 * fields?: {
 *  outageText?: { value?: string };
 * }
 *
 * @returns The sitecore jss data without the value:
 * {
 *  outageText?: string;
 * }
 *
 */
export function mapWithoutValueProperty<T extends { [index: string]: unknown }>(fields: T) {
  const obj = { ...fields } as { [K in keyof T]: unknown };
  let key: keyof T;
  for (key in fields) {
    const value = fields[key];
    if (value && typeof value === 'object' && 'value' in value) {
      obj[key] = value.value;
    }
  }
  return obj as FilterValuePropertyFromFields<T>;
}
