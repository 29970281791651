import React from 'react';
import * as styles from './JumpToMainContent.css';

type JumpToMainContentProps = {
  id: string;
  anchor: string;
  title: string;
};

export const JumpToMainContent = ({
  id,
  anchor,
  title,
}: JumpToMainContentProps) => {
  return (
    <div className={styles.wrapper} tabIndex={-1} id={id}>
      <a href={anchor} className={styles.button}>
        {title}
      </a>
    </div>
  );
};
