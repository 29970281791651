import React from 'react';
import { useMedia } from 'react-use';

import { IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps } from '@alliander-fe/api';

import {
  AnimatedArrowComponent,
  Box,
  ButtonUnset,
  Grid,
  GridItem,
  Stack,
  Text,
  TextLink,
  VisuallyHidden,
} from '@ads-core/components';
import { mq } from '@ads-core/breakpoints';
import { ChevronRightIcon, NoElectricalConnectionIcon, NoGasConnectionIcon } from '@ads-core/icons';

import { setReplaceText, getPostalCodeAreasNumbers, isOutageCause, causeMapper } from 'src/utils';
import { setOutageEndDate } from 'src/utils/outageEndDate';

import * as styles from './OutageBlock.css';

type OutageBlockWrapperProps = {
  children: React.ReactNode;
};

const Container = ({ children }: OutageBlockWrapperProps) => {
  return (
    <Box
      paddingInline={{ initial: 6, md: 10 }}
      paddingBlock={{ initial: 6, md: 8 }}
      width="100%"
      bg="containerLight"
      borderRadius="brandLg"
      position="relative"
    >
      {children}
    </Box>
  );
};

type OutageBlockProps = {
  handleToggleDialog: (data: OutageProps) => void;
  resolvedOutages?: boolean;
} & OutageProps;

const Current = ({ handleToggleDialog, resolvedOutages, ...props }: OutageBlockProps) => {
  const isDesktop = useMedia(mq.md);
  const [isHovered, setIsHovered] = React.useState(false);
  const cause = getCause(props.cause);
  const postalCodeAreaNumbers = getPostalCodeAreasNumbers(props.affectedPostalCodes);
  const a11yButtonAddon = postalCodeAreaNumbers
    ? `${cause.toLowerCase()} op postcode ${postalCodeAreaNumbers}`
    : '';

  return (
    <>
      {isDesktop ? (
        <Grid gap={{ initial: 2, sm: 4, lg: 6 }} asChild>
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <GridItem columnStart="1" columnEnd="4" asChild>
              <Stack gap={2}>
                <Text size="label">{cause}</Text>
                <Stack direction="row" gap={2}>
                  {props.energyType === 'Stroom' ? (
                    <NoElectricalConnectionIcon size="xs" />
                  ) : (
                    <NoGasConnectionIcon size="xs" />
                  )}
                  <Text>{props.energyType}</Text>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem columnStart="4" columnEnd="7" asChild>
              <Stack gap={2}>
                <Text size="label">Tijdsindicatie</Text>
                <Text>{props.duration}</Text>
              </Stack>
            </GridItem>

            <GridItem columnStart="7" columnEnd="12" asChild>
              <Stack gap={2}>
                <Text size="label">Straten</Text>
                <Text>
                  {props.affectedStreets
                    ? setReplaceText(props.affectedStreets, { ';': ', ' })
                    : ''}
                </Text>
              </Stack>
            </GridItem>

            <GridItem columnStart="12" columnEnd="-1">
              <Stack alignX="end" alignY="center" isFullHeight isFullWidth>
                <ButtonUnset
                  className={styles.ListItemLink}
                  onClick={() => handleToggleDialog(props)}
                >
                  <AnimatedArrowComponent
                    label={`Bekijk alle details ${a11yButtonAddon}`}
                    active={isHovered}
                    size={'large'}
                  />
                </ButtonUnset>
              </Stack>
            </GridItem>
          </div>
        </Grid>
      ) : (
        <Stack gap={4}>
          <Stack gap={2}>
            <Text size="label">{cause}</Text>
            <Stack direction="row" gap={2}>
              {props.energyType === 'Stroom' ? (
                <NoElectricalConnectionIcon size="xs" />
              ) : (
                <NoGasConnectionIcon size="xs" />
              )}
              <Text>{props.energyType}</Text>
            </Stack>
          </Stack>

          <Stack gap={2}>
            <Text size="label">Tijdsindicatie</Text>
            <Text>{props.duration}</Text>
          </Stack>

          <Stack gap={2}>
            <Text size="label">Straten</Text>
            <Text>
              {props.affectedStreets ? setReplaceText(props.affectedStreets, { ';': ', ' }) : ''}
            </Text>
          </Stack>

          <Box paddingTop={2}>
            <TextLink afterIcon={<ChevronRightIcon />} asChild>
              <button className={styles.ListItemLink} onClick={() => handleToggleDialog(props)}>
                Bekijk alle details
                <VisuallyHidden>{a11yButtonAddon}</VisuallyHidden>
              </button>
            </TextLink>
          </Box>
        </Stack>
      )}
    </>
  );
};

const Resolved = ({ handleToggleDialog, ...props }: OutageBlockProps) => {
  const isDesktop = useMedia(mq.md);
  const [isHovered, setIsHovered] = React.useState(false);
  const cause = getCause(props.cause);
  const postalCodeAreaNumbers = getPostalCodeAreasNumbers(props.affectedPostalCodes);
  const a11yButtonAddon = postalCodeAreaNumbers
    ? `${cause.toLowerCase()} op postcode ${postalCodeAreaNumbers}`
    : '';

  return (
    <>
      {isDesktop ? (
        <Grid gap={{ initial: 2, sm: 4, lg: 6 }} asChild>
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <GridItem columnStart="1" columnEnd="4" asChild>
              <Stack gap={2}>
                <Text size="label">{cause}</Text>
                <Stack direction="row" gap={2}>
                  {props.energyType === 'Stroom' ? (
                    <NoElectricalConnectionIcon size="xs" />
                  ) : (
                    <NoGasConnectionIcon size="xs" />
                  )}
                  <Text>{props.energyType}</Text>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem columnStart="4" columnEnd="7" asChild>
              <Stack gap={2}>
                <Text size="label">Datum</Text>
                <Text>{props.reportTime ? setOutageEndDate(props.reportTime) : ''}</Text>
              </Stack>
            </GridItem>

            <GridItem columnStart="7" columnEnd="12" asChild>
              <Stack gap={2}>
                <Text size="label">Oorzaak</Text>
                <Text>{causeMapper(props.cause)}</Text>
              </Stack>
            </GridItem>

            <GridItem columnStart="12" columnEnd="-1">
              <Stack alignX="end" alignY="center" isFullHeight isFullWidth>
                <ButtonUnset
                  className={styles.ListItemLink}
                  onClick={() => handleToggleDialog(props)}
                >
                  <AnimatedArrowComponent
                    label={`Bekijk alle details ${a11yButtonAddon}`}
                    active={isHovered}
                    size={'large'}
                  />
                </ButtonUnset>
              </Stack>
            </GridItem>
          </div>
        </Grid>
      ) : (
        <Stack gap={4}>
          <Stack gap={2}>
            <Text size="label">{cause}</Text>
            <Stack direction="row" gap={2}>
              {props.energyType === 'Stroom' ? (
                <NoElectricalConnectionIcon size="xs" />
              ) : (
                <NoGasConnectionIcon size="xs" />
              )}
              <Text>{props.energyType}</Text>
            </Stack>
          </Stack>

          <Stack gap={2}>
            <Text size="label">Datum</Text>
            <Text>{props.endDate ? setOutageEndDate(props.endDate) : ''}</Text>
          </Stack>

          <Stack gap={2}>
            <Text size="label">Oorzaak</Text>
            <Text>{causeMapper(props.cause)}</Text>
          </Stack>

          <Box paddingTop={2}>
            <TextLink afterIcon={<ChevronRightIcon />} asChild>
              <button className={styles.ListItemLink} onClick={() => handleToggleDialog(props)}>
                Bekijk alle details
                <VisuallyHidden>{a11yButtonAddon}</VisuallyHidden>
              </button>
            </TextLink>
          </Box>
        </Stack>
      )}
    </>
  );
};

const AllResolved = ({ handleToggleDialog, ...props }: OutageBlockProps) => {
  const isDesktop = useMedia(mq.md);
  const [isHovered, setIsHovered] = React.useState(false);
  const cause = getCause(props.cause);
  const postalCodeAreaNumbers = getPostalCodeAreasNumbers(props.affectedPostalCodes);
  const a11yButtonAddon = postalCodeAreaNumbers
    ? `${cause.toLowerCase()} op postcode ${postalCodeAreaNumbers}`
    : '';

  return (
    <>
      {isDesktop ? (
        <Grid gap={{ initial: 2, sm: 4, lg: 6 }} asChild>
          <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <GridItem columnStart="1" columnEnd="4" asChild>
              <Stack gap={2}>
                <Text size="label">Postcode</Text>
                <Stack direction="row" gap={2}>
                  <Text>{postalCodeAreaNumbers}</Text>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem columnStart="4" columnEnd="7" asChild>
              <Stack gap={2}>
                <Text size="label">{cause}</Text>
                <Stack direction="row" gap={2}>
                  {props.energyType === 'Stroom' ? (
                    <NoElectricalConnectionIcon size="xs" />
                  ) : (
                    <NoGasConnectionIcon size="xs" />
                  )}
                  <Text>{props.energyType}</Text>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem columnStart="7" columnEnd="9" asChild>
              <Stack gap={2}>
                <Text size="label">Datum</Text>
                <Text>{props.reportTime ? setOutageEndDate(props.reportTime) : ''}</Text>
              </Stack>
            </GridItem>

            <GridItem columnStart="9" columnEnd="12" asChild>
              <Stack gap={2}>
                <Text size="label">Oorzaak</Text>
                <Text>{causeMapper(props.cause)}</Text>
              </Stack>
            </GridItem>

            <GridItem columnStart="12" columnEnd="-1">
              <Stack alignX="end" alignY="center" isFullHeight isFullWidth>
                <ButtonUnset
                  className={styles.ListItemLink}
                  onClick={() => handleToggleDialog(props)}
                >
                  <AnimatedArrowComponent
                    label={`Bekijk alle details ${a11yButtonAddon}`}
                    active={isHovered}
                    size={'large'}
                  />
                </ButtonUnset>
              </Stack>
            </GridItem>
          </div>
        </Grid>
      ) : (
        <Stack gap={4}>
          <Stack gap={2}>
            <Stack gap={2}>
              <Text size="label">Postcode</Text>
              <Stack direction="row" gap={2}>
                <Text>{getPostalCodeAreasNumbers(props.affectedPostalCodes)}</Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack gap={2}>
            <Text size="label">{cause}</Text>
            <Stack direction="row" gap={2}>
              {props.energyType === 'Stroom' ? (
                <NoElectricalConnectionIcon size="xs" />
              ) : (
                <NoGasConnectionIcon size="xs" />
              )}
              <Text>{props.energyType}</Text>
            </Stack>
          </Stack>

          <Stack gap={2}>
            <Text size="label">Datum</Text>
            <Text>{props.endDate ? setOutageEndDate(props.endDate) : ''}</Text>
          </Stack>

          <Stack gap={2}>
            <Text size="label">Oorzaak</Text>
            <Text>{causeMapper(props.cause)}</Text>
          </Stack>

          <Box paddingTop={2}>
            <TextLink afterIcon={<ChevronRightIcon />} asChild>
              <button className={styles.ListItemLink} onClick={() => handleToggleDialog(props)}>
                Bekijk alle details
                <VisuallyHidden>{a11yButtonAddon}</VisuallyHidden>
              </button>
            </TextLink>
          </Box>
        </Stack>
      )}
    </>
  );
};

const getCause = (cause: string | undefined | null) => {
  return isOutageCause(cause) ? 'Storing' : 'Gepland onderhoud';
};

export default {
  Container,
  Current,
  Resolved,
  AllResolved,
};
