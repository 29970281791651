import React from 'react';
import { InputDate, Stack, Button } from '@ads-core/components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { format, subDays } from 'date-fns';
import { minMaxDateValidator } from './utils/minMaxDateValidator';
import { dateRangeValidator } from './utils/dateRangeValidator';

export type Dates = {
  startDate?: string;
  endDate?: string;
};

type FilterMinMaxDateProps = {
  onChangeValue: (props: Dates) => void;
  value?: Dates;
};

export const FilterMinMaxDate = ({ onChangeValue, value }: FilterMinMaxDateProps) => {
  const date = new Date();

  const maxDate = format(date, 'yyyy-MM-dd');
  const minDate = format(subDays(date, 366), 'yyyy-MM-dd');
  const messageMinDate = format(minDate, 'dd/MM/yyyy');
  const messageMaxDate = format(maxDate, 'dd/MM/yyyy');
  const { formState, handleSubmit, register, getValues } = useForm({
    mode: 'onTouched',
    values: value,
  });

  const onSubmit: SubmitHandler<Dates> = (data) => {
    const { endDate, startDate } = data;

    onChangeValue({
      startDate: startDate,
      endDate: endDate,
    });
  };

  return (
    <Stack gap={4} asChild>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputDate
          label="Datum van"
          max={maxDate}
          min={minDate}
          error={formState?.errors?.startDate?.message as string | undefined}
          {...register('startDate', {
            validate: {
              minDaysInFuture: (v) =>
                minDateValidation({
                  message: `Vul voor "Datum van" een datum in tussen ${messageMinDate} en ${messageMaxDate}.`,
                  date: v,
                }),
              maxDaysInFuture: (v) =>
                maxDateValidation({
                  message: `Vul voor "Datum van" een datum in tussen ${messageMinDate} en ${messageMaxDate}.`,
                  date: v,
                }),
              validRange: (v) =>
                rangeDateValidation({
                  startDate: v,
                  endDate: getValues('endDate'),
                  message: `De datum in "Datum van" kan niet later zijn dan de datum in "Datum tot".`,
                }),
            },
          })}
        />
        <InputDate
          label="Datum tot"
          max={maxDate}
          min={minDate}
          error={formState?.errors?.endDate?.message as string | undefined}
          {...register('endDate', {
            validate: {
              minDaysInFuture: (v) =>
                minDateValidation({
                  message: `Vul voor "Datum tot" een datum in tussen ${messageMinDate} en ${messageMaxDate}.`,
                  date: v,
                }),
              maxDaysInFuture: (v) =>
                maxDateValidation({
                  message: `Vul voor "Datum tot" een datum in tussen ${messageMinDate} en ${messageMaxDate}.`,
                  date: v,
                }),
              validRange: (v) =>
                rangeDateValidation({
                  startDate: getValues('startDate'),
                  endDate: v,
                  message: `De datum in "Datum van" kan niet later zijn dan de datum in "Datum tot".`,
                }),
            },
          })}
        />
        <Button type="submit" aria-label="Zoek op datum">
          Zoeken
        </Button>
      </form>
    </Stack>
  );
};

type DateValidation = {
  date?: string;
  message: string;
};

const minDateValidation = ({ date, message }: DateValidation): string | undefined => {
  if (!date) return undefined;
  return !minMaxDateValidator(date) ? message : undefined;
};

const maxDateValidation = ({ date, message }: DateValidation): string | undefined => {
  if (!date) return undefined;
  return !minMaxDateValidator(date) ? message : undefined;
};

type DateRangeValidation = Dates & {
  message: string;
};

const rangeDateValidation = ({ message, ...dates }: DateRangeValidation): string | undefined => {
  const isValidRange = dateRangeValidator(dates);
  return !isValidRange ? message : undefined;
};
