import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VW227jNhB971cMYCzQBRyBHN4k7lPWl8eif1BIouQ4cWxHcrJOiv57QW3cQkNOgcaIYJ+5HY7mkCz+kONeHaUS8OcvAHd3t9/SwwK/Y4nu2wxHD4vt9DfHlYdFiXLj1nNce1gI4ap7NceNh4XeStSrOW6ZPI6pWzJ4xeA1k7+ZeBrj7BxvGf6Byd8xeM/gO6bPDwyfPcP/kcnzxOQ5MOt6ZngeGfzE4GcGf2HwgeE/Mv4Xxv+V8X9j8B9MnquHRW/iZ46/R1y2WvRz/MPDQtZNW7VzXAqmsIwK295v9VYQAzKvTKp/uCoao3mT4QhYjoBjeiJLZvhkVNtmtV5viJpllJtdycqWxBD1ZlbSmIoYWmZjkIGZWBklt642qy019FOELcsNMex+dnjlaEs41ckoO7f+rhVlFXW3MVuxpguMwutC77pADFF5neltTw1Ren3f646MnIza60MXklQnriVnbh0vXMQwDUPlJO1VFOBauErQXl08XIb6OJ7roTteiPGVG6G3/4r6wY3qlRu8d25aPpiFomBag5weEZniqBi6qLnD0zBdRssZOC0id/BhxdGNWsTOBYXEMJ19WJZBEkM7zbBtBY2IWgwKeyS7IHaMTjBq8V4JIWh3dx7E+QpSxGcZH8OuqX9Vbgkol6CWIAqBX5eTl5aFc86er6BUoRFl3l1q5Zz8jEEslLIq1nBFaSsmRFaItxCJhUFpJ1qFkA6ZmE93W1gjIhVTKBSizHuLUjhX2hurwlmHdvqGUpXMSoSxiJX8Spr24GF/HLsLiOkTg/NTss84Zqfm0U/G8XTYBybX08wlm+XgIfYq1ONDFxg5Pc99siI9etDnKwFP7BmH/942EtMLJ4eB2yFGTnIXZrPBV64Gd+tA7tqBV2a7xXdOWR+MslTc6ZoQqqYjhrjT1W1oeiLr6XLfNwFrcomZbve9aroklZ7US0AzvWEC2swrjbf6MgFLDyLCt39irqL5pzhvD+JRezDiCwGbHNM2RyrkSHUeZLqo3ufq7zxgWuvBA6bF9h4wrfboQaXVnjyotNrBg06rPXvQad6jB5NmOHmwKbOzB5dyePHTJk3QwUOV5h1jz1LnS2xaCr/GrqXwm4ffh9N1/1zDb6e3egn3w74+LGGsj+Pd2A17cgTFG/3/C7h6sIl43mNXKfiRa6oWHmRBjk4tc29FY85V5V621tGVbE3a5KZF25yry42bLnOulYd4khK0zrk2HmRKoI2u5NaqQ04busu5RhmlXHefrn/9DXoeySOlEAAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA52U3W6jMBCF7/MUo0qVkgtXgEmadW/yJpWxh2CV2KxtUrqrvPuKnyCThF22dzAz55vxwcPhA79yy0/o4OdOHettBL9XALk1p+4BoMTcMyBxFD2/rQAuKwBvJrmoj19Wh1tY/BgWzYCCJg9gSVd7IwnHmuvzgEUfD7bkkC89YdtlMy4+jtbUWhJhSmMZnLldE/IeO0V1TGOxeQs0TPuCiEKVcp1slgGyOQBdCOBzgHQhYD8FFOaM9huH3y3TFBPNNww7zgGWGpbPAZYaJqeA/zBscvjXTnNSmnwq6QsGdJdWTVCw7y9xiQ3JuFOOwba9vmP+x12e0hdKKQ2L+F1Rsg3z2T/yot9KbDyRKIzlXhnNQBuN7cZUxqk+YrHkXp1xou2787JkUGuHvpWI2rrWm8oo7dFO6xnLMDcWO50w2qP2DJ6epr145kxZ+24Cb6phe4NFHhy97nuB6lgE+3//obzl2lXcou5m/EWUlth0tHE8OVrFIA7ieRCnbfxwQqk4OGERNXAtYR185jhNo6rp79pASIdf0chuX1rJdfCklYx/u7/iX3f7GzoO9BB4deLaMxngfwDM76+OMAYAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var TopTaskLink = 'q6igu5c';
export var TopTasks = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{color:{purple:{initial:'q6igu55'},blue:{initial:'q6igu56'}}},compoundVariants:[],defaultVariants:{color:'purple'},baseClassName:'q6igu54'});
export var arrowHover = 'q6igu50';
export var arrowHoverOut = 'q6igu51';
export var arrowWhiteHover = 'q6igu52';
export var arrowWhiteHoverOut = 'q6igu53';
export var task = 'q6igu5b';
export var taskBlock = 'q6igu5f';
export var tasksWrapper = 'q6igu5d';
export var variableBlockMobile = 'q6igu5e';
export var variableBlockTask = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{items:{'2':{initial:'q6igu58'},'3':{initial:'q6igu59'},'4':{initial:'q6igu5a'}}},compoundVariants:[],defaultVariants:{},baseClassName:'q6igu57'});