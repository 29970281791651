import React from 'react';
import {
  TextField,
  ImageFieldValue,
  LinkField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { InspirationalCardsSitecore, ScrollLinkWrapper } from '@ads-core/components';
import { useAdsContext } from '@ads-core/providers';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreImage, SitecoreText, renderJssLink } from '@alliander-fe/jss-utils';

type InspirationalCardsSitecoreProps = ComponentProps & {
  rendering: {
    uid: string;
  };
  fields: {
    title: TextField;
    articles: Array<{
      fields: {
        tag: TextField;
        shortTitle: TextField;
        button: LinkField;

        infoImage: {
          value: ImageFieldValue & { height: string; width: string; alt: string };
        };
      };
    }>;
  };
  params?: {
    anchor?: string;
  };
};

const InspirationalCards = (props: InspirationalCardsSitecoreProps) => {
  const adsContext = useAdsContext();

  const siteCoreArticles = props.fields.articles.map((article) => {
    return {
      button: renderJssLink(article.fields.button),
      tag: <SitecoreText field={article.fields.tag} editable tag="p" />,
      shortTitle: (
        <SitecoreText
          field={article.fields.shortTitle}
          editable
          tag={props.fields.title.value ? 'h3' : 'h2'}
        />
      ),
      infoImage: <SitecoreImage field={article.fields.infoImage} editable />,
    };
  });

  return (
    <ScrollLinkWrapper anchorId={props.params?.anchor ?? undefined}>
      <InspirationalCardsSitecore
        title={<SitecoreText field={props.fields.title} editable tag="h2" />}
        articles={siteCoreArticles}
        onColoredSection={adsContext.ids.includes(props.rendering.uid)}
      />
    </ScrollLinkWrapper>
  );
};

export default withDatasourceCheck()<ComponentProps>(InspirationalCards);
