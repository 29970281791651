import React from 'react';
import type { ImageProps } from 'next/image';
import NextImage from 'next/image';
import { VisuallyHidden } from '@ads-core/components';
import { useSitecoreContext } from '@liander/context';
import ReactIs from 'react-is';
import * as styles from './Image.css';

type ImageProviderProps = {
  src: string;
  width: number;
  height: number;
  alt: string;
  placeholder?: ImageProps['placeholder'] | React.ReactElement;
};

export const Image = (props: ImageProviderProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { isEditor } = useSitecoreContext();

  const { placeholder, alt = '', ...imageProps } = props;
  const isCustomPlaceholder = ReactIs.isElement(placeholder);

  if (isEditor) {
    // eslint-disable-next-line @next/next/no-img-element
    return <img alt={alt} {...imageProps} />;
  }

  return (
    <>
      {isLoading && isCustomPlaceholder && placeholder}
      <ImageHider isLoading={isLoading}>
        <NextImage
          quality={100}
          sizes="(min-width: 320px) 100vw, (min-width: 768px) 80vw, (min-width: 1024px) 50vw, (min-width: 1680px) 1680px, (min-width: 1680px) 1680px, 100vw"
          alt={alt}
          {...imageProps}
          placeholder={isCustomPlaceholder ? undefined : placeholder}
          onLoadingComplete={() => {
            setIsLoading(false);
          }}
        />
      </ImageHider>
    </>
  );
};

const ImageHider = ({ children, isLoading }: { children: React.ReactNode; isLoading: boolean }) => {
  if (isLoading) {
    return <VisuallyHidden className={styles.visuallyHiddenFix}>{children}</VisuallyHidden>;
  }

  return <>{children}</>;
};
