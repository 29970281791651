import { getDate, getMonth, getYear } from 'date-fns';

// Because we only have data of this or last year we can set the year based on the month.
// If the date is in the future the date is from last year otherwise it's this year.
//
// @param date: '11 oktober'
// @return date: '11 oktober 2024' or '11 oktober 2023' when the month hasn't been passed.

export const setOutageEndDate = (date: string): string => {
  const [day, month] = date.split(' ');
  const currentDate = new Date();

  const currentDay = getDate(currentDate);
  const currentMonth = getMonth(currentDate);
  const currentYear = getYear(currentDate);

  if (!months.includes(month)) return date;

  const inputMonth = months.indexOf(month);
  const inputDay = Number(day);

  if (inputMonth > currentMonth || (inputMonth === currentMonth && inputDay > currentDay)) {
    return `${date} ${currentYear - 1}`;
  }

  return `${date} ${currentYear}`;
};

const months = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
];
