import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { AsChild, InternalComponentProps } from '../../components/Slot';
import * as styles from './Grid.css';

type GridProps = {
  children: React.ReactNode;
} & styles.GridVariants;

type GridComponentProps = GridProps & AsChild & InternalComponentProps;

export const Grid = React.forwardRef<HTMLDivElement, GridComponentProps>(
  (
    {
      children,
      className = '',
      asChild,
      as = 'div',
      style = {},
      alignY,
      alignX,
      gap,
      rowGap,
      columnGap,
      columns,
      rows,
      subGridColumn,
      subGridRow,
    },
    ref
  ) => {
    const classNames = styles.grid({
      alignX,
      alignY,
      gap,
      rowGap,
      columnGap,
      columns,
      rows,
      subGridColumn,
      subGridRow,
    });
    const Component = asChild ? Slot : as;

    return (
      <Component
        className={clsx(classNames, className)}
        style={style}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
