import React from 'react';
import { ButtonLink } from '../ButtonLink';
import { PageGrid } from '../PageGrid';
import { GridItem } from '../GridItem';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { CombineProps } from '../../types/type-helpers';

type ParagraphProps = {
  text: string;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
};

type ParagraphSitecoreProps = {
  text: React.ReactNode;
  primaryButton?: React.ReactElement;
  secondaryButton?: React.ReactElement;
};

type ParagraphViewProps = CombineProps<ParagraphProps, ParagraphSitecoreProps>;

export const Paragraph = (props: ParagraphProps) => {
  return <ParagraphView {...props} />;
};

export const ParagraphSitecore = (props: ParagraphSitecoreProps) => {
  return <ParagraphView {...props} />;
};

const ParagraphView = ({
  text,
  primaryButton,
  secondaryButton,
}: ParagraphViewProps) => {
  if (!text && !primaryButton && !secondaryButton) {
    return null;
  }

  return (
    <PageGrid>
      {text ? (
        <GridItem
          asChild
          columnStart={{ initial: '1', md: '3' }}
          columnEnd={{ initial: '-1', md: '-3' }}
        >
          <Box width={'100%'} overflow={'hidden'}>
            {text}
          </Box>
        </GridItem>
      ) : null}
      {primaryButton || secondaryButton ? (
        <GridItem
          columnStart={{ initial: '1', md: '3' }}
          columnEnd={{ initial: '-1', md: '-3' }}
        >
          <Stack gap={4} direction="row">
            {primaryButton ? (
              <ButtonLink size="large" tone="onLight" variant="primary" asChild>
                {primaryButton}
              </ButtonLink>
            ) : null}
            {secondaryButton ? (
              <ButtonLink size="large" tone="onLight" variant="ghost" asChild>
                {secondaryButton}
              </ButtonLink>
            ) : null}
          </Stack>
        </GridItem>
      ) : null}
    </PageGrid>
  );
};
