import React from 'react';

import { useMutation } from '@tanstack/react-query';

import {
  IntegrationApi,
  IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps,
} from '@alliander-fe/api';

import { Dialog } from '@ads-core/components';
import { useDialogContext } from '@ads-core/providers';

import { isOutageCause, outageFormatTexts, rewriteEnergyType } from 'src/utils';

import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';
import { LocationOutages } from '../LocationOutages';
import { OutageFilter } from '../OutageFilter';
import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';

export const SitecoreOutageOnLocation = ({
  maintenanceDetailsModalTitle,
  outageDetailsModalTitle,
  outageDetailsModalText,
  outageDetailsModalLinkOne,
  outageDetailsModalLinkTwo,
  ...props
}: FilteredOutagesOnLocationProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const [outageDialogData, setOutageDialogData] = React.useState<OutageProps>();
  const showRecentResolved = Boolean(props.onlyShowRecentlyResolvedOutages);
  const showAllResolved = Boolean(props.onlyShowAllResolvedOutages);
  const resolvedOutages = showAllResolved || showRecentResolved;

  const locationOutages = showRecentResolved || (!showRecentResolved && !showAllResolved);

  const specificOutageMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsGetSpecificOutage,
  });

  React.useEffect(() => {
    if (!isOpen) {
      setOutageDialogData(undefined);
    }
  }, [isOpen]);

  const handleOpenDialog = async (props: OutageProps) => {
    if (!props.outageNumber) return;

    const outageModalData = await specificOutageMutation.mutateAsync({
      outageNumber: props.outageNumber.toString(),
    });

    const hasData = Boolean(outageModalData && outageModalData.outageNumber);
    const modalOutageData = hasData ? outageModalData : props;

    const isMaintenance = !isOutageCause(modalOutageData.cause);
    const modalTitleText = isMaintenance
      ? maintenanceDetailsModalTitle?.toString()
      : outageDetailsModalTitle?.toString();

    // In case of Resoled outages show outageDetailsText.
    // In case of unResolved outages only show outageDetailsText on Outages.
    const detailsText = outageDetailsModalText?.toString() ?? '';
    const outageMessage = !isMaintenance ? detailsText : undefined;
    const outageDetailsText = resolvedOutages ? detailsText : outageMessage;

    const transformedEnergyType = rewriteEnergyType(modalOutageData.energyType);

    const outageData = {
      type: transformedEnergyType || '',
      places: modalOutageData.affectedPlaces || '',
      postalCode: modalOutageData.affectedPostalCodes || '',
    };

    const modalTitle = outageFormatTexts(modalTitleText || '', outageData);

    const dialogData: OutageDialogContentProps = {
      ...modalOutageData,
      title: modalTitle,
      affectedStreets: modalOutageData.affectedStreets?.replace(/;/g, ', ') || '',
      affectedPostalCodes: modalOutageData.affectedPostalCodes?.replace(/;/g, ', ') || '',
      energyType: transformedEnergyType,
      outageDetailsText: outageDetailsText,
      outageDetailsLinkOne: outageDetailsModalLinkOne,
      outageDetailsLinkTwo: outageDetailsModalLinkTwo,
    };

    if (hasData) {
      dialogData.statusDescription = '';
      dialogData.postalCode = '';
    }

    setOutageDialogData(dialogData);
    openDialog();
  };

  return (
    <>
      {outageDialogData ? (
        <Dialog>
          <OutageDialogContent {...outageDialogData} />
        </Dialog>
      ) : null}
      {locationOutages ? (
        <LocationOutages {...props} handleOutageDialog={handleOpenDialog} />
      ) : (
        <OutageFilter {...props} handleOutageDialog={handleOpenDialog} />
      )}
    </>
  );
};
