import React from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { IconButton } from '../IconButton';
import { ChevronDownIcon } from '../../icons/index';
import { Stack } from '../Stack';
import { StackItem } from '../StackItem';
import { Box } from '../Box';
import { ButtonUnset } from '../ButtonUnset';
import { Divider } from '../Divider';

import { IconButtonVariants } from '../IconButton/IconButton.css';
import * as styles from './Accordion.css';

type AccordionBaseProps = {
  children?: React.ReactElement | React.ReactElement[];
};

type AccordionMultipleProps = {
  activeIds?: string[];
  onAccordionOpen: (id: string[], opened: boolean) => void;
};

type AccordionSingleProps = {
  activeIds?: string;
  onAccordionOpen: (id: string, opened: boolean) => void;
};

type AccordionProps =
  | ({ type: 'single' | undefined } & AccordionBaseProps & AccordionSingleProps)
  | ({ type: 'multiple' } & AccordionBaseProps & AccordionMultipleProps);

export const Accordion = (props: AccordionProps) => {
  if (props.type === 'multiple') {
    return (
      <Box width="100%">
        <RadixAccordion.Root
          type="multiple"
          onValueChange={(openedItem) => {
            props.onAccordionOpen(openedItem, Boolean(openedItem.length));
          }}
          value={props.activeIds}
        >
          {props.children}
        </RadixAccordion.Root>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <RadixAccordion.Root
        type="single"
        collapsible
        onValueChange={(value) => {
          props.onAccordionOpen(value, Boolean(value));
        }}
        value={props.activeIds}
      >
        {props.children}
      </RadixAccordion.Root>
    </Box>
  );
};

type AccordionContainerProps = {
  children: React.ReactElement;
  boxed?: boolean;
  firstChild?: boolean;
};

const Container = ({
  children,
  firstChild,
  boxed,
}: AccordionContainerProps) => {
  return (
    <>
      <Box
        paddingBottom={6}
        paddingTop={firstChild ? 0 : 6}
        paddingRight={boxed ? { initial: 10, md: 20 } : undefined}
        position="relative"
      >
        {children}
      </Box>
      <Divider />
    </>
  );
};

type AccordionItemProps = {
  content: React.ReactNode;
  title: React.ReactNode;
  titleName?: string | number;
  id: string;
  isActive?: boolean;
  icon?: React.ReactElement;
};

const Item = ({ isActive, icon, ...item }: AccordionItemProps) => {
  return (
    <RadixAccordion.Item value={item.id}>
      <RadixAccordion.Header asChild>
        <Stack
          direction="row"
          alignY="center"
          gap={2}
          isFullWidth
          className={styles.titleWrapper}
        >
          <StackItem grow asChild className={styles.title}>
            <RadixAccordion.Trigger asChild>
              <ButtonUnset>{item.title}</ButtonUnset>
            </RadixAccordion.Trigger>
          </StackItem>
          {icon ? (
            <StackItem shrink={false} className={clsx(styles.triggerCnt)}>
              {icon}
            </StackItem>
          ) : null}
        </Stack>
      </RadixAccordion.Header>
      <Box asChild>
        <RadixAccordion.Content forceMount hidden={!isActive}>
          {item.content}
        </RadixAccordion.Content>
      </Box>
    </RadixAccordion.Item>
  );
};

type AccordionIconProps = {
  isActive?: boolean;
  label?: string | number;
  className?: string;
} & Pick<IconButtonVariants, 'tone' | 'variant' | 'size'>;

const Icon = ({
  isActive,
  label,
  tone,
  size,
  variant,
  className,
}: AccordionIconProps) => {
  const iconLabel = label ? label.toString().toLowerCase() : 'accordion';
  return (
    <RadixAccordion.Trigger asChild>
      <IconButton
        className={clsx(styles.trigger, className)}
        size={size}
        variant={variant}
        tone={tone}
        icon={<ChevronDownIcon />}
        label={!isActive ? `Sluit ${iconLabel}` : `Open ${iconLabel}`}
      />
    </RadixAccordion.Trigger>
  );
};

Accordion.Item = Item;
Accordion.Icon = Icon;
Accordion.Container = Container;
