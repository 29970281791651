import { IntegrationApi } from '@alliander-fe/api';
import {
  ComponentParams,
  ComponentRendering,
  HtmlElementRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useParams } from 'src/hooks/useParams';
import { rewriteEnergyType } from 'src/utils';

export interface OutageLocationContainerProps {
  rendering: ComponentRendering;
  params?: ComponentParams;
}

// The 'OutageLocationContainer' makes it possible to hide components when we don't have any outages.
// This component uses the base it's requests on the url params so this component within multiple situations.

// We can hide the 'OutagesSMSService' and 'FollowUpStepsSection' when:
// There's no outage on a specific request [postalCode && houseNumber].
// There's no outage on a unspecific request [reference].
// There's no outage on a location [city or path]

// For now we only able to display or hide the 'OutagesSMSService' and 'FollowUpStepsSection' static.

// When this component is in the editor we don't hide any of the components.

const OutageLocationContainer = (props: OutageLocationContainerProps) => {
  const router = useRouter();

  // When the editor is active we also get the params. This way we can detect if the component is viewed in te editor.
  // currently there's no better solution
  const isEditor = props.params && typeof props.params !== 'undefined';

  const disabledComponents = ['OutagesSMSService', 'FollowUpStepsSection'];

  const { postalCode, houseNumber, houseNumberAddition, city, reference } = useParams();

  // This component can be used in multiple places. These multiple places have multiple ways of having the location in the url
  // TODO: We should be getting the location from a reliable single source, or not depend on this local call to render the component
  const path = router.query.path || [];
  const location = city || path[path.length - 1] || null;

  // I. isOutagesSpecificQuery
  // Only if we don't have a city and reference and we have as postalCode and houseNumber
  // request serviceAvailabilityEndpointsGetServiceAvailabilityDetails to get the reference
  // when we have the reference > outagesEndpointsGetSpecificOutage.
  const isOutagesSpecificQuery = Boolean(!reference && postalCode && houseNumber) && !isEditor;

  // II. isNotOutagesSpecificQuery
  // Only if we don't have a city and have a reference
  // when we have the reference > outagesEndpointsGetSpecificOutage.
  const isNotOutagesSpecificQuery = Boolean(reference && !isOutagesSpecificQuery) && !isEditor;

  // III. isOutagesLocationQuery: Request when we only have a "location" param
  // Only if we have a "city" or "path[path.length - 1]" and noting else.
  const isOutagesLocationQuery =
    Boolean(location && !isOutagesSpecificQuery && !isNotOutagesSpecificQuery) && !isEditor;

  // Request "outagesDetailsQuery" only when isOutagesSpecificQuery
  const outagesDetailsQuery = useQuery({
    enabled: isOutagesSpecificQuery,
    queryKey: [postalCode, houseNumber, houseNumberAddition, 'outagesDetailsQuery'],
    queryFn: () =>
      IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails({
        postalCode: postalCode,
        houseNumber: Number(houseNumber),
        addition: houseNumberAddition || '',
      }),
  });

  // Request "outagesSpecificQuery" only when referenceNumber
  const referenceNumber = isNotOutagesSpecificQuery
    ? reference
    : outagesDetailsQuery.data?.outageNumber?.toString();

  const outagesSpecificQuery = useQuery({
    enabled: Boolean(referenceNumber),
    queryKey: [referenceNumber, 'outagesSpecific'],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetSpecificOutage({
        outageNumber: referenceNumber ?? '',
      }),
    select: (data) => {
      return {
        ...data,
        energyType: rewriteEnergyType(data.energyType),
      };
    },
  });

  // Request only when we have a location
  const outagesLocationQuery = useQuery({
    enabled: isOutagesLocationQuery,
    queryKey: [location, 'outagesLocationQuery'],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location,
        Resolved: false,
        Amount: 0,
        ReturnCountOnly: true,
      }),
  });

  // Return if the request has a result.
  const hasResults = (): boolean => {
    if (isOutagesSpecificQuery || isNotOutagesSpecificQuery) {
      return Boolean(outagesSpecificQuery.data);
    }

    if (isOutagesLocationQuery) {
      return Boolean(outagesLocationQuery.data?.count);
    }

    return true;
  };

  // Maps the components and remove the components that we wil exclude.
  const components: Array<ComponentRendering | HtmlElementRendering> = props.rendering.placeholders
    ? props.rendering.placeholders['outage-location-container-content'].filter((component) => {
        if (!hasResults() && isComponentRendering(component)) {
          return !disabledComponents.find(
            (disableComponent) => disableComponent === component.componentName
          );
        }

        return true;
      })
    : [];

  return (
    <Placeholder
      name="outage-location-container-content"
      rendering={{
        ...props.rendering,
        placeholders: {
          'outage-location-container-content': components,
        },
      }}
    />
  );
};

export function isComponentRendering(
  component?: ComponentRendering | HtmlElementRendering
): component is ComponentRendering {
  return (
    typeof component !== 'undefined' &&
    'componentName' in component &&
    component.componentName !== ''
  );
}

export default OutageLocationContainer;
