import React from 'react';
import {
  LinkField,
  RichTextField,
  TextField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useSitecoreContext } from 'src/context/SitecoreContext';
import { ComponentProps } from 'src/jss/lib/component-props';
import {
  Box,
  Card,
  DictionaryDialog,
  Grid,
  GridItem,
  Heading,
  PageGrid,
  Stack,
} from '@ads-core/components';
import { renderJssLink, renderJssRichText, SitecoreText } from '@alliander-fe/jss-utils';

export type CardsSectionProps = ComponentProps & {
  fields?: {
    title?: TextField;
    text?: RichTextField;

    cardOneTitle?: TextField;
    cardOneText?: RichTextField;
    cardOneLink?: LinkField;
    cardOneLinkEnableSecondary?: { value: boolean };

    cardTwoTitle?: TextField;
    cardTwoText?: RichTextField;
    cardTwoLink?: LinkField;
    cardTwoLinkEnableSecondary?: { value: boolean };

    cardThreeTitle?: TextField;
    cardThreeText?: RichTextField;
    cardThreeLink?: LinkField;
    cardThreeLinkEnableSecondary?: { value: boolean };
  };
};

const CardsSection = (props: CardsSectionProps) => {
  const id = React.useId();
  const { isEditor } = useSitecoreContext();

  const cards = mappedCards(props.fields);

  const columnsMedium = cards.length < 2 ? 1 : 2;
  const columnsLarge = cards.length as 1 | 2 | 3;

  const hasMainTitle = Boolean(props?.fields?.title);

  const text = renderJssRichText(props?.fields?.text);

  return (
    <Stack gap={{ initial: 8, md: 16 }}>
      {props.fields?.title?.value || text || isEditor ? (
        <PageGrid>
          <GridItem
            columnStart={{ initial: '1', lg: '3' }}
            columnEnd={{
              initial: '-1',
              lg: `span 8`,
            }}
          >
            <Stack gap={8}>
              {props.fields?.title?.value || isEditor ? (
                <Heading size="h1" as="h2">
                  <DictionaryDialog>
                    <SitecoreText field={props?.fields?.title} editable />
                  </DictionaryDialog>
                </Heading>
              ) : null}
              {text}
            </Stack>
          </GridItem>
        </PageGrid>
      ) : null}

      {cards.length ? (
        <PageGrid>
          <GridItem asChild>
            <Grid
              columns={{
                initial: 1,
                md: columnsMedium,
                lg: columnsLarge,
              }}
              rows={3}
            >
              {cards.map((card, index) => (
                <GridItem
                  columnStart="auto"
                  columnEnd="span 1"
                  rowEnd="span 3"
                  asChild
                  key={`card-${index}`}
                >
                  <Grid subGridRow asChild key={`card-${id}-${index}`} columns={1} gap={8}>
                    <Card.Container>
                      {card?.title ? (
                        <Card.Title size="h4" as={hasMainTitle ? 'h3' : 'h2'}>
                          {card.title}
                        </Card.Title>
                      ) : null}
                      {card?.text ? (
                        <Box paddingBottom={card.link ? 2 : 0}>
                          <Card.Text>{card.text}</Card.Text>
                        </Box>
                      ) : null}
                      {card?.link ? (
                        <Card.ButtonLink
                          variant={card.isSecondaryLink ? 'ghost' : 'primary'}
                          asChild
                        >
                          {card.link}
                        </Card.ButtonLink>
                      ) : null}
                    </Card.Container>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </GridItem>
        </PageGrid>
      ) : null}
    </Stack>
  );
};

const mappedCards = (fields: CardsSectionProps['fields']) => {
  const cards = ['One', 'Two', 'Three'] as const;

  const combinedCards = cards
    .map((card) => {
      const title = fields?.[`card${card}Title`];
      const text = fields?.[`card${card}Text`];
      const link = fields?.[`card${card}Link`];
      const isSecondary = fields?.[`card${card}LinkEnableSecondary`];

      if (title?.value || text?.value || link?.value?.href) {
        return {
          title: <SitecoreText field={title} editable />,
          text: renderJssRichText(text),
          link: link ? renderJssLink(link) : undefined,
          isSecondaryLink: Boolean(isSecondary?.value),
        };
      }
    })
    .filter((card) => card !== undefined);

  return combinedCards;
};

export default withDatasourceCheck()<CardsSectionProps>(CardsSection);
