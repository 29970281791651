import { Textarea } from '@ads-core/components';
import { MultiLineStringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { getOptions, isIBAN } from '@alliander-fe/validation';
import { encodeNameToReactHookFormFormat, extractString, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { PropsWithConditions, InputViewWithParameters } from '../types';
import { getValidationModels } from '../utils/getValidationModels';
import { validateInput } from '../utils/validateInput';

type Props = PropsWithConditions<
  ValueFieldProps<InputViewWithParameters<MultiLineStringInputViewModel>>
>;

export const TextareaFieldMapper = ({ tracker, field }: Props) => {
  const { formState, getValues, register, setValue } = useFormContext();

  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  const { fieldKey } = field.model.conditionSettings;
  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const {
    exact18NumbersValidator,
    exact8NumbersValidator,
    isPostalCodeValidator,
    isIBANValidator,
    onlyNumbersValidator,
    isNameValidator,
    stringLengthValidator,
    conditionallyRequiredValidator,
  } = getValidationModels(field.model.validationDataModels);

  const error = extractString(formState.errors[name]?.message);
  const options = getOptions(field);
  const methods = register(name, {
    ...options,
    validate: {
      IBAN: (v) => {
        return isIBANValidator ? isIBAN(v, isIBANValidator.message) : true;
      },
      validationModel: (v) => {
        const validation = validateInput(v, [
          exact18NumbersValidator,
          exact8NumbersValidator,
          isPostalCodeValidator,
          isNameValidator,
          onlyNumbersValidator,
          stringLengthValidator,
          conditionallyRequiredValidator,
        ]);

        if (validation) {
          return validation;
        }
      },
    },
  });

  return (
    <Textarea
      error={error}
      tone="onLight"
      label={getLabel(field.model.title, !!options.required)}
      className={field.model.cssClass}
      rows={field.model.rows}
      minLength={field.model.minLength}
      maxLength={field.model.maxLength}
      placeholder={field.model.placeholderText}
      {...methods}
      onFocus={() => tracker.onFocusField(field, getValues(name))}
      onBlur={(e) => {
        tracker.onBlurField(field, getValues(name), error ? [error] : undefined);
        methods.onBlur(e);
      }}
      onChange={(e) => {
        const value = e.target.value;

        // Because a IBAN number is always in uppercase we change the input.
        if (isIBANValidator) {
          setValue(name, value.toUpperCase());
        }

        // When the name validator is active we change the first letter to a uppercase.
        if (isNameValidator) {
          setValue(name, value.charAt(0).toUpperCase() + value.slice(1));
        }
      }}
    />
  );
};
