import { setReplaceText } from '@alliander-fe/validation';
import { FilteredNetworkCheckProps } from '..';
import { DialogStatusProps } from '../_DialogNetwork';

// Maps the api response to the correct response for the view
export const mappedNetworkOutages = ({
  networkCheck,
  isHealthyGas,
  isHealthyElectricity,
  replaceText,
}: {
  networkCheck?: FilteredNetworkCheckProps;
  isHealthyGas?: boolean;
  isHealthyElectricity?: boolean;
  replaceText?: { [key: string]: string };
}): DialogStatusProps => {
  const replaceTextParameters = replaceText ? replaceText : { '{}': '' };

  if (!isHealthyGas && !isHealthyElectricity) {
    return {
      title: setReplaceText(networkCheck?.gasAndElectricityOutageTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.gasAndElectricityOutageText, replaceTextParameters),
      available: false,
    };
  }

  if (!isHealthyGas) {
    return {
      title: setReplaceText(networkCheck?.gasOutageTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.gasOutageText, replaceTextParameters),
      available: false,
    };
  }

  if (!isHealthyElectricity) {
    return {
      title: setReplaceText(networkCheck?.electricityOutageTitle, replaceTextParameters) ?? '',
      message: setReplaceText(networkCheck?.electricityOutageText, replaceTextParameters),
      available: false,
    };
  }

  return {
    title: setReplaceText(networkCheck?.noOutagesTitle, replaceTextParameters) ?? '',
    message: setReplaceText(networkCheck?.noOutagesText, replaceTextParameters),
    available: true,
  };
};
