import { FilteredCongestionCheckerParticulierProps } from 'components/CongestionCheckerParticulier';
import { MappedCongestionDataProps } from './mappedResponse';

type mappedErrorResponseProps = {
  code: string;
} & Pick<
  FilteredCongestionCheckerParticulierProps,
  | 'errorTitle'
  | 'errorMessage'
  | 'errorTitleAddressHasNoLowVoltageConnection'
  | 'errorMessageAddressHasNoLowVoltageConnection'
  | 'errorTitleOtherGridOperatorElectricity'
  | 'errorMessageOtherGridOperatorElectricity'
  | 'errorTitlePostalCodeHouseNumberNotFound'
  | 'errorMessagePostalCodeHouseNumberNotFound'
>;

export const mappedErrorResponse = ({
  code,
  ...message
}: mappedErrorResponseProps): MappedCongestionDataProps | undefined => {
  switch (code) {
    case 'POSTCODE_HUISNUMMER_NIET_GEVONDEN':
      return {
        feedbackTitle: message.errorTitlePostalCodeHouseNumberNotFound,
        feedbackText: message.errorMessagePostalCodeHouseNumberNotFound,
      };
    case 'ANDERE_NETBEHEERDER_ELEKTRA':
      return {
        feedbackTitle: message.errorTitleOtherGridOperatorElectricity,
        feedbackText: message.errorMessageOtherGridOperatorElectricity,
      };
    case 'ADRES_HEEFT_GEEN_LAAGSPANNINGSAANSLUITING':
      return {
        feedbackTitle: message.errorTitleAddressHasNoLowVoltageConnection,
        feedbackText: message.errorMessageAddressHasNoLowVoltageConnection,
      };
    default:
      return {
        feedbackTitle: message.errorTitle,
        feedbackText: message.errorMessage,
      };
  }
};
