import { setReplaceText } from '@alliander-fe/validation';
import { CongestionLevel } from 'src/utils/congestionLevel';
import { FilteredNetworkCheckProps } from '..';
import { DialogStatusProps } from '../_DialogNetwork';

// Maps the api response to the correct response for the view
export const mappedNetworkCongestions = ({
  networkCheck,
  level,
  replaceText,
}: {
  networkCheck?: FilteredNetworkCheckProps;
  level?: CongestionLevel;
  replaceText?: { [key: string]: string };
}): DialogStatusProps | undefined => {
  const replaceTextParameters = replaceText ? replaceText : { '{}': '' };

  if (!level) return;

  switch(level) {
    case 'None':
      return {
        title: setReplaceText(networkCheck?.noCongestionsTitle, replaceTextParameters) ?? '',
        message: setReplaceText(networkCheck?.noCongestionsText, replaceTextParameters),
        available: true,
      };
    case 'Yellow':
    case 'Red':
    case 'Purple':
      return {
        title: setReplaceText(networkCheck?.veryFullCongestionsTitle, replaceTextParameters) ?? '',
        message: setReplaceText(networkCheck?.veryFullCongestionsText, replaceTextParameters),
        available: false,
      };
    case 'GridFull':
      return {
        title: setReplaceText(networkCheck?.fullCongestionsTitle, replaceTextParameters) ?? '',
        message: setReplaceText(networkCheck?.fullCongestionsText, replaceTextParameters),
        available: false,
      };
    default: 
      return;
  }
};
