import { Heading, RichText, Stack } from '@ads-core/components';

type MessageProps = {
  title?: string;
  text?: string;
};

export const Message = ({ title, text }: MessageProps) => {
  if (!title && !text) return null;

  return (
    <Stack direction="column" gap={4}>
      {title ? (
        <Heading size="h5" as="h3">
          {title}
        </Heading>
      ) : null}
      {text ? <RichText>{text?.toString()}</RichText> : null}
    </Stack>
  );
};
