export function formatDate(timestamp: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Europe/Amsterdam',
  };

  const date = new Date(timestamp);
  return date.toLocaleDateString('nl-NL', options);
}

// Create a regex for the replacements and replace the values in the string.
export const setReplaceText = (string: string, replacements: { [key: string]: string }): string => {
  const pattern = new RegExp(
    Object.keys(replacements)
      .map((key) => `\\${key}`)
      .join('|'),
    'g'
  );
  return string.replace(pattern, (match: string) => replacements[match]);
};

// Transforms location names like: 'T HARDE or 't harde to 't Harde and ST.-JACOBIPAROCHIE to St.-Jacobiparochie.
export const capitalizeLocationName = (location: string): string => {
  const splitOnSpace = location.split(' ');
  let capitalizedLocationName = '';

  splitOnSpace.forEach((word) => {
    capitalizedLocationName = capitalizedLocationName.length
      ? `${capitalizedLocationName} ${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}`
      : `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}`;
  });

  if (/[-]/.test(capitalizedLocationName)) {
    const splitOnDash = capitalizedLocationName.split('-');
    let capitalizedLocationNameWithDashes = '';

    splitOnDash.forEach((word) => {
      capitalizedLocationNameWithDashes = capitalizedLocationNameWithDashes.length
        ? `${capitalizedLocationNameWithDashes}-${
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          }`
        : `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()}`;
    });

    return capitalizedLocationNameWithDashes;
  }

  return capitalizedLocationName;
};

export const firstLetterToUpperCase = (text?: string | undefined | null) => {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
};

/**
 *
 * @param postalCodeInput The postal code that will be formatted: "1234AB"
 * @returns The postal code formatted: "1234 AB"
 */
export function formatPostalCode(postalCodeInput: string) {
  const postalCode = postalCodeInput.toUpperCase().replace(/ /g, '');
  return postalCode.substring(0, 4) + ' ' + postalCode.substring(4);
}

/**
 * Format the postal code so we can use it within our api's
 * @param postalCode (1234aa, 1234 aa or 1234 AA)
 * @returns postalCode 1234AA
 */
export const formatApiPostalCode = (postalCode: string | undefined): string | undefined => {
  if (!postalCode) return undefined;
  return postalCode.replaceAll(' ', '').toUpperCase();
};

/**
 *
 * @param cities A list of cities that will be formatted into a string: ["amsterdam", "utrecht", "rotterdam"]
 * @returns A string with the cities formatted in a list: "Amsterdam, Utrecht en Rotterdam"
 */
export function formatCityListIntl(cities: string[]) {
  const formatter = new Intl.ListFormat('nl', { style: 'long', type: 'conjunction' });
  return formatter.format(cities);
}
