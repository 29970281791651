import React from 'react';
import { InputBase, InputBaseProps } from '../InputBase';
import { Stack } from '../Stack';
import { Box } from '../Box';

type InputTextProps = Omit<InputBaseProps, 'type'>;

export const InputDate = React.forwardRef<HTMLInputElement, InputTextProps>(
  (props, ref) => {
    return (
      <Stack asChild alignY="center" direction="row">
        <Box display="inlineBlock" asChild>
          <InputBase type="date" {...props} ref={ref} />
        </Box>
      </Stack>
    );
  }
);
