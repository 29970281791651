import React from 'react';
import { InputNumber, InputText, Stack, StackItem, Alert, RichText } from '@ads-core/components';
import { ValueFieldProps } from '@sitecore-jss/sitecore-jss-react-forms';
import { useFormContext } from 'react-hook-form';
import { IntegrationApi } from '@alliander-fe/api';
import {
  getOptions,
  isValidValidationModel,
  validationModels,
  getEnabledValidation,
} from '@alliander-fe/validation';
import { StringInputViewModel } from '@sitecore-jss/sitecore-jss-forms';
import { useQuery } from '@tanstack/react-query';
import { extractString, encodeNameToReactHookFormFormat, getLabel } from '../utils/utils';
import { useConditionalActions } from '../hooks';
import { AddressPropsWithConditions, InputViewWithParameters } from '../types';

type Props = AddressPropsWithConditions<
  ValueFieldProps<InputViewWithParameters<StringInputViewModel>>
>;

export const AddressBlockFieldMapper = ({ field }: Props) => {
  const name = encodeNameToReactHookFormFormat(field.valueField.name);
  useSetComputedAddressField({ name }); // set computed value based on other values

  const names = getNames(name);

  const { formState, register, resetField } = useFormContext();
  const { fetchStatus, isNetworkOperator, setFocused } = useGetAddress({ names });

  const id = React.useId();
  const warningMessageId = `${id}-addressBlockWarning`;

  const { fieldKey } = field.model.conditionSettings;
  const networkOperatorCheck = field.model.enableNetworkOperatorCheck;

  const warningMessage = () => {
    if (fetchStatus === 'notFound') {
      return '<p>We hebben uw adres niet gevonden. Controleer uw gegevens of voer uw adres handmatig in.</p>';
    }

    if (networkOperatorCheck && !isNetworkOperator && fetchStatus === 'success') {
      return '<p>We zien aan uw adres dat Liander niet uw netbeheerder is. Controleer uw gegevens of zoek uw netbeheerder op via <a href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">mijnaansluiting.nl</a>.</p>';
    }
  };

  // Extract the errors in a single function call so we memoize the result
  const errors = React.useMemo(
    () =>
      Object.entries(names).reduce((acc, [key, formKey]: [keyof Names, string]) => {
        acc[key] = extractString(formState.errors[formKey]?.message);
        return acc;
      }, {} as Errors),
    [formState.errors, names]
  );

  const { isHidden } = useConditionalActions({ fieldKey, name });

  if (isHidden) return null;

  const options = getOptions(field, ['required']);

  const isAdresBlockPostalCode = getEnabledValidation(
    validationModels.IS_ADRES_BLOCK_POSTAL_CODE,
    field.model.validationDataModels
  );

  const postalCodeMethods = register(names.postalCode, {
    ...options,
    validate: {
      validationModel: (v) => {
        if (isAdresBlockPostalCode && v) {
          return isValidValidationModel(isAdresBlockPostalCode, v);
        }

        return true;
      },
    },
  });

  const houseNumberMethods = register(names.houseNumber, options);
  const addendumMethods = register(names.addendum);
  const cityMethods = register(names.city, options);
  const streetMethods = register(names.street, options);

  const isFetching = fetchStatus === 'loading';
  const streetAndPlacePlaceholder = isFetching ? 'Bezig met ophalen van adresgegevens...' : '';

  return (
    <div onBlur={() => setFocused(false)} onFocus={() => setFocused(true)}>
      <Stack gap={6} aria-describedby={warningMessageId}>
        <InputText
          tone="onLight"
          label={getLabel('Postcode', !!options.required)}
          placeholder="1234AB"
          error={errors.postalCode}
          {...postalCodeMethods}
          onChange={(e) => {
            resetField(names.city);
            resetField(names.street);
            postalCodeMethods.onChange(e);
          }}
        />
        <Stack
          direction={{
            initial: 'column',
            sm: 'row',
          }}
          gap={6}
          isFullWidth
        >
          <StackItem grow>
            <InputNumber
              tone="onLight"
              label={getLabel('Huisnummer', !!options.required)}
              {...houseNumberMethods}
              error={errors.houseNumber}
              onChange={(e) => {
                resetField(names.city);
                resetField(names.street);
                houseNumberMethods.onChange(e);
              }}
            />
          </StackItem>
          <StackItem grow>
            <InputText
              tone="onLight"
              label={getLabel('Toevoeging', false)}
              error={errors.addendum}
              {...addendumMethods}
              onChange={(e) => {
                resetField(names.city);
                resetField(names.street);
                addendumMethods.onChange(e);
              }}
            />
          </StackItem>
        </Stack>
        <InputText
          disabled={isFetching}
          placeholder={streetAndPlacePlaceholder}
          error={errors.street}
          tone="onLight"
          label={getLabel('Straat', !!options.required)}
          {...streetMethods}
        />
        <InputText
          disabled={isFetching}
          placeholder={streetAndPlacePlaceholder}
          error={errors.city}
          tone="onLight"
          label={getLabel('Plaats', !!options.required)}
          {...cityMethods}
        />

        <Alert variant="warning" id={warningMessageId} role="alert">
          {warningMessage() ? <RichText tone="onLight">{warningMessage()}</RichText> : null}
        </Alert>
      </Stack>
    </div>
  );
};

function getNames(name: string) {
  return {
    postalCode: `postalCode_${name}`,
    houseNumber: `houseNumber_${name}`,
    addendum: `addendum_${name}`,
    city: `city_${name}`,
    street: `street_${name}`,
  };
}

type Names = ReturnType<typeof getNames>;
type Errors = { [K in keyof Names]: Names[K] | undefined };

/**
 * This extracted hook will fetch the address based on the postal code and house number. We only fetch data on certain conditions, primarily onBlur.
 * @param names The keys of the form fields
 * @returns The fetch status, if the network operator is operational and a function to set the focus state
 */
function useGetAddress({ names }: { names: Record<string, string> }) {
  const { formState, getValues, resetField, setValue } = useFormContext();

  const [focused, setFocused] = React.useState<boolean>();

  const currentPostalCode = getValues(names.postalCode);
  const currentHouseNumber = getValues(names.houseNumber);
  const currentAddendum = getValues(names.addendum);
  const currentCity = getValues(names.city);
  const currentStreet = getValues(names.street);

  const shouldFetch =
    Boolean(!focused) &&
    Boolean(currentPostalCode && currentHouseNumber) &&
    Boolean(!currentCity && !currentStreet) &&
    Boolean(formState.dirtyFields[names.postalCode] || formState.dirtyFields[names.houseNumber]);

  const query = useQuery({
    enabled: shouldFetch,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: [currentPostalCode, currentHouseNumber, currentAddendum],
    retry: 0,
    queryFn: () =>
      IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails({
        postalCode: currentPostalCode,
        houseNumber: Number(currentHouseNumber),
        addition: currentAddendum || '',
      }),
  });

  const data = query.data;

  const isNetworkOperator = Boolean(
    data?.electricityNetwork?.isOperational || data?.gasNetwork?.isOperational
  );

  function getStatus() {
    switch (true) {
      // @ts-expect-error IntegrationApi shouldnt return a 404
      case query.error?.status === 404:
        return 'notFound';
      case query.isLoading:
        return 'loading';
      case query.isError:
        return 'error';
      case query.isSuccess:
        return 'success';
      default:
        return 'idle';
    }
  }

  const fetchStatus = getStatus();

  React.useEffect(() => {
    if (query.isSuccess && data) {
      resetField(names.city);
      resetField(names.street);

      const city = data.address?.city;
      const street = data.address?.street || '';

      const formattedCity = city ? city.charAt(0).toUpperCase() + city.slice(1).toLowerCase() : '';

      setValue(names.city, formattedCity);
      setValue(names.street, street);
    }

    if (query.isError) {
      resetField(names.city);
      resetField(names.street);
    }
  }, [data, query.isSuccess, query.isError]);

  return { fetchStatus, isNetworkOperator, setFocused };
}

/**
 * Sitecore needs one computed field in the format: 2727CD|157|A|Zoetermeer|Velddreef. This hook automatically computes that based off other values.
 * @param param0
 */
function useSetComputedAddressField({ name }: { name: string }) {
  const { watch, setValue } = useFormContext();
  const names = getNames(name);
  const value = watch(Object.values(names)).join('|');

  React.useEffect(() => {
    setValue(name, value);
  }, [name, setValue, value]);
}
