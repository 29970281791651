import React from 'react';
import type { Link } from '@alliander-fe/sitecore-types';
import { FooterComponent } from '@ads-core/components';
import { InternalOrExternalLink } from 'src/components/InternalOrExternalLink';

type Socials = 'facebook' | 'instagram' | 'linkedIn';

type FooterProps = {
  fields: {
    sections: Array<{
      subHeader: string;
      links: Link[];
    }>;
    copyright: Link[];
    socials: Record<Socials, Link>;
  };
};

const Footer = (props: FooterProps) => {
  const mappedCopyrightLinks = props.fields?.copyright.map((link) => (
    <InternalOrExternalLink href={link.href} target={link.target} key={link.text}>
      {link.text}
    </InternalOrExternalLink>
  ));

  const mappedSections = props.fields?.sections.map((section) => {
    const mappedLinks = section.links.map((link) => {
      return (
        <InternalOrExternalLink
          href={link.href}
          target={link.isExternal ? '_blank' : ''}
          key={link.text}
        >
          {link.text}
        </InternalOrExternalLink>
      );
    });

    return {
      subHeader: section.subHeader,
      links: mappedLinks,
    };
  });

  const mappedSocials = Object.fromEntries(
    Object.entries(props.fields?.socials).map(([key, link]) => {
      return [key, <a href={link.href} target={link.target} key={key} aria-label={link.text} />];
    })
  ) as Record<Socials, React.ReactElement>;

  return (
    <FooterComponent
      sections={mappedSections}
      copyright={mappedCopyrightLinks}
      socials={mappedSocials}
    />
  );
};

export default Footer;
