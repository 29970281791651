import { renderToString } from 'react-dom/server';
import parse from 'html-react-parser';
import {
  LinkField,
  Link,
  RichTextProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecoreRichText } from './jss-components';

export function renderJssLink(
  field: LinkField,
  augmentedChildren?: React.ReactNode
) {
  const isEditor = Boolean(field.editableFirstPart);
  const hasElement = Boolean(field.value.href && field.value.text);

  const element = (
    <Link field={field} editable>
      {isEditor
        ? undefined
        : augmentedChildren
        ? augmentedChildren
        : field.value.text}
    </Link>
  );

  // Note: we render a static link in the editor, because of the weird inner workings of the JSS <Link> component
  return hasElement
    ? isEditor
      ? (parse(renderToString(element)) as React.ReactElement)
      : element
    : undefined;
}

export function renderJssRichText(field: RichTextProps['field']) {
  const isEditor = Boolean(field?.editable);
  const hasElement = Boolean(field?.value);

  const element = <SitecoreRichText field={field} editable />;

  return isEditor || hasElement ? element : undefined;
}
