import { ValidationModels } from '../types/global';
import { isValidValidationModel } from './isValidValidationModel';

export function isPhoneNumber(
  validationModel: ValidationModels[0] | undefined,
  inputValue: string
): boolean | string {
  if (!validationModel || !inputValue) {
    return true;
  }

  const maxLength = 17; // Max length of a phone number is 15 but we also need to add up the + and space
  const isValid = isValidValidationModel(validationModel, inputValue);

  if (typeof isValid === 'string') {
    return isValid;
  }

  if (inputValue.length > maxLength) {
    return validationModel.message;
  }

  return true;
}

export const telephonePattern = {
  value: /^\+\d{1,4} (?!0)\d{7,14}$/i,
  message: 'Vul een geldig telefoonnummer inclusief landcode in: +31 612345678',
};
